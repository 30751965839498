/* 
 * @Author: 曹俊杰 
 * @Date: 2022-02-17 15:04:21
 * @Module: 默认layouts
 */
 <template>
  <div class="page">
    <el-container>
      <el-aside width="auto">
        <Menu />
      </el-aside>
      <el-container style="min-width: 1000px;">
        <el-header height="70px">
          <Header />
        </el-header>
        <div style="height:calc(100vh - 70px );overflow:auto;" id="projectContainer">
          <el-main :style="hiddenFooter?'min-height:calc(100vh - 70px )':'min-height:calc(100vh - 130px )'" style="background:rgba(0,0,0,.03);">
            <authorizedUser />
            <keep-alive>
              <router-view v-if="$route.meta.keepAlive"></router-view>
              <!-- <router-view></router-view> -->
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
          </el-main>
          <el-footer v-show="!hiddenFooter">
            <footer>
              ©2014-2022 中能魔力（河南）网络科技有限公司版权所有 豫ICP备14100822号-1 豫公网安备 44030602004264号文本
            </footer>
          </el-footer>
        </div>
      </el-container>
    </el-container>
    <service />
  </div>
</template>
 <script>
import service from "@/components/service.vue";
import authorizedUser from "@/components/authorizedUser.vue"
import Menu from "@/components/layouts/Menu.vue"
import Header from "@/components/layouts/Header.vue"
import { mapState } from "vuex";
export default {
  components: { service, authorizedUser, Menu, Header },
  data () {
    return {
      hiddenFooter: false,
      menuList: [
        {
          title: '首页',
          name: '/home',
          icon: 'el-icon-ali-shouye1',
          authority: 'all'
        },
        {
          title: '电子合同',
          name: '/electronicDocuments',
          icon: 'el-icon-ali-dianzihetong',
          authority: 'all'
        },
        {
          title: '精准取证',
          name: '/accurateEvidence',
          icon: 'el-icon-ali-jingzhunquzheng',
          authority: 'all'
        },
        {
          title: '归档管理',
          name: '/archiveManagement',
          icon: 'el-icon-ali-guidangguanli',
          authority: 'all'
        },
        {
          title: '三要素核查',
          name: '/inspect',
          icon: 'el-icon-ali-sanyaosuhecha',
          authority: 'all'
        },
        {
          title: '查询小号',
          name: '/search',
          icon: 'el-icon-search',
          authority: 'all'
        },
        {
          title: '用户中心',
          name: '/accountManagement',
          icon: 'el-icon-ali-yonghuzhongxin',
          authority: 'all'
        },
        {
          title: '费用中心',
          name: '/expenseCenter',
          icon: 'el-icon-ali-feiyongzhongxin',
          authority: 'all'
        },
        {
          title: '员工管理',
          name: '/accountColleague',
          icon: 'el-icon-user',
          authority: 'all'
        },
        {
          title: '印章管理',
          name: '/sealManagement',
          icon: 'el-icon-ali-xingzhuang',
          authority: 'all'
        },
        {
          title: '法务学院',
          name: '/instituteJustice',
          icon: 'el-icon-ali-fawuxueyuan',
          authority: 'all'
        },
        {
          title: '帮助中心',
          name: '/help',
          icon: 'el-icon-ali-wenhao-bangzhu',
          authority: 'all'
        }
      ]
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      userLogList: state => state.userLog.userLogList
    })
  },
  mounted () {
  },
  methods: {
  },
  watch: {
    "$route.meta": {
      handler ({ hiddenFooter = false }) {
        this.hiddenFooter = hiddenFooter
      },
      immediate: true
    }
  }
};
 </script>
 <style lang='scss' scoped>
@import "~@/assets/style/var.scss";

.page {
  min-height: 100vh;
  header {
    padding: 0;
  }
}
footer {
  display: flex;
  align-items: center;
  font-size: 13px;
  justify-content: center;
}
</style>