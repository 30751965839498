/* 
 * @Author: 曹俊杰 
 * @Date: 2022-02-22 18:45:42
 * @Module: 法务学院
 */
 <template>
  <div class="instituteJustice">
    <el-card shadow="never" :body-style="{position:'relative'}">
      <div slot="header">
        <hdq-section title="法务学院" moreText="" />
      </div>

      <div class="content">
        <div class="item" v-for="item in list" :key="item.videoId" @click="()=>openVideo(item)">
          <div class="cover" :style="`background-image:url(${$oss+item.cover})`"></div>
          <!-- <el-image style="width: 100%; height: 153px;border-radius: 4px;" :src="$oss+item.cover" fit="cover"></el-image> -->
          <div class="item-t">{{item.title}}</div>
          <!-- <div class="item-c">{{item.summary}}</div> -->
          <div class="item-b">
            <div class="item-b-l">{{item.updatedAt|timeFormat}}</div>
            <div class="item-b-r">{{item.viewCount}} 次播放</div>
          </div>
        </div>
      </div>
      <el-empty v-if="list.length==0"></el-empty>
      <hdqPagination layout="prev, pager, next,sizes" ref="pagination" @change="paginationChange" />
    </el-card>
    <el-dialog :destroy-on-close="true" v-if="dialogVisible" :visible.sync="dialogVisible" width="48%">
      <video autoplay :src="this.$oss+videoUrl" controls></video>
    </el-dialog>
  </div>
</template>
 <script>
import hdqPagination from "@/components/hdqPagination.vue"
export default {
  components: { hdqPagination },
  data () {
    return {
      list: [],
      // bannerList: [],
      dialogVisible: false,
      videoUrl: ""
    };
  },
  activated () {
    //开启了keepAlive:true后再次进入，以前的搜索条件和页数都不会变，无论什么情况都调用一下获取数据的接口，这样就能得到当前搜索条件和页数的最新数据
    if (this.$route.meta.ifDoFresh) {
      //重置ifDoFresh
      this.$route.meta.ifDoFresh = false;
      //获取列表数据方法第一参数为是否重置搜索条件和页数
      this.getVideo();
    }
  },
  mounted () {
    // this.getBannerList()
  },
  beforeRouteEnter (to, from, next) {
    if (from.name !== "instituteJusticeDetail") {
      to.meta.ifDoFresh = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },
  methods: {
    async getVideo (current = 1, size = 10) {
      const { status, data } = await this.$api.getVideo({ current, size })
      if (status === 200) {
        const { records } = data
        this.list = records
        this.$refs.pagination.createPagination(data)
      }
    },
    paginationChange ({ current, size }) {
      this.getVideo(current, size)
    },
    openVideo (item) {
      this.$router.push({ path: `/instituteJustice/${item.videoId}` })
      // this.dialogVisible = true
      // this.videoUrl = item.url
    }
    // async getBannerList () {
    //   const { status, data } = await this.$api.getBannerList({ label: 'video' })
    //   if (status === 200) {
    //     this.bannerList = data
    //   }
    // },
    // bannerOpen (url) {
    //   window.open(url);
    // }
  },
};
 </script>
 <style lang='scss' scoped>
.instituteJustice {
  & /deep/.el-dialog__header {
    padding: 0;
    position: relative;
    &btn {
      position: absolute;
      right: -30px;
      top: 0px;
      .el-dialog__close {
        color: #fff;
      }
    }
  }
  & /deep/.el-dialog__body {
    padding: 0px;
    video {
      width: 100%;
      vertical-align: middle;
      height: 520px;
      background: #000;
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-bottom: 26px;
      width: calc((100% - (26px * 3)) / 4);
      margin-right: 26px;
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      .cover {
        width: 100%;
        // height: 150px;
        padding-bottom: calc(100% / 271 * 153);
        border-radius: 4px;
        background-size: 100% auto;
        background-repeat: no-repeat;
      }
      &:nth-child(4n) {
        margin-right: 0px;
      }
      // &:hover {
      //   opacity: 0.8;
      //   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      // }
      &-t {
        // text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        margin-top: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis; /* for Opera */
      }
      // &-c {
      //   padding: 4px;
      //   font-size: 14px;
      //   color: #555;
      //   margin: 5px 0;
      //   overflow: hidden;
      //   white-space: nowrap;
      //   text-overflow: ellipsis;
      //   -o-text-overflow: ellipsis; /* for Opera */
      // }
      &-b {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-top: 8px;
      }
    }
  }
}
</style>