/* 
 * @Author: 曹俊杰 
 * @Date: 2022-02-24 18:28:42
 * @Module: 查看更多
 */
 <template>
  <div class="hdqSection">
    <div class="left">
      {{title}}
    </div>
    <div class="more" @click="more">{{moreText}}</div>
  </div>
</template>
 <script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    moreText: {
      type: String,
      default: '更多'
    }
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {
    more () {
      this.$emit("more")
    }
  },
};
 </script>
 <style lang='scss' scoped>
.hdqSection {
  line-height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  // font-family: PingFangSC-Medium, PingFang SC;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  .left {
    display: flex;
    align-items: center;
  }
  .more {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
  }
  margin-top: 10px;
}
</style>