/* 
 * @Author: 曹俊杰 
 * @Date: 2022-04-28 19:39:02
 * @Module: 二维码弹出框
 */
 <template>
  <div class="qrDialog">
    <el-dialog destroy-on-close :visible.sync="dialogVisible" @close="close" custom-class="content" width="100">
      <vue-qr :text="url" :size="200" style="margin:0 auto;"></vue-qr>
      <slot :url="url"></slot>
    </el-dialog>
  </div>
</template>
 <script>
import vueQr from 'vue-qr'
export default {
  components: { vueQr },
  data () {
    return {
      dialogVisible: false,
      url: ''
    };
  },
  mounted () { },
  methods: {
    show (url) {
      this.url = url;
      this.dialogVisible = true
    },
    hidden () {
      this.url = "";
      this.dialogVisible = false
    },
    close () {
      this.$emit('close')
    }
  },
};
 </script>
 <style lang='scss' scoped>
.qrDialog {
  &/deep/ .el-dialog__header {
    padding: 0;
  }
  &/deep/ .el-dialog__body {
    display: flex;
    flex-direction: column;
    text-align: center;
    .text {
      margin-top: 20px;
      span:nth-child(2) {
        color: #0062ff;
      }
    }
  }
}
</style>
