import api from '@/utils/api'
const state = {
	helpList: []
}
const mutations = {
	setHelpList (state, payload) {
		state.helpList = payload
	}
}
const actions = {
	async getHelpList ({ commit }) {
		const { status, data } = await api.getHelpList()
		if (status === 200) {
			commit('setHelpList', data)
		}
	}
}
export default {
	namespaced: true,
	state,
	actions,
	mutations
}