/* 
 * @Author: 曹俊杰 
 * @Date: 2022年10月31日
 * @Module: 筛选表单
 */
 <template>
  <el-form :inline="true" label-suffix=":" :model="searchForm" size="mini" style="margin-top:20px;">
    <el-form-item label="昵称">
      <el-input v-model="searchForm.nickname" clearable placeholder="昵称"></el-input>
    </el-form-item>
    <el-form-item label="ID">
      <el-input v-model="searchForm.outerId" clearable placeholder="ID"></el-input>
    </el-form-item>
    <el-form-item label="状态">
      <el-select v-model="searchForm.state" clearable placeholder="请选择状态" style="width: 100%">
        <el-option :key="item.value" :label="item.label" :value="item.value" v-for="item in stateList" />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">搜索</el-button>
    </el-form-item>
  </el-form>
</template>
 <script>
export default {
  components: {
  },
  props: {
    searchForm: {
      type: Object,
      default: () => ({
        nickname: '',
        state: null,
        outerId: null
      })
    }
  },
  data () {
    return {
      stateList: [
        {
          label: '查询中',
          value: 1
        },
        {
          label: '创建失败',
          value: 2
        },
        {
          label: '结束',
          value: 3
        },
        {
          label: '取消',
          value: 4
        },
      ]
    };
  },
  mounted () { },
  methods: {
    onSubmit () {
      this.$emit('submit')
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>