/* 
 * @Author: 曹俊杰 
 * @Date: 2022-05-10 17:52:49
 * @Module: 三要素核查
 */
 <template>
  <div class="inspect">
    <div class="top">
      <div class="btn" @click="$router.push({name: 'inspiectSearch'})">立即核查</div>
    </div>
    <el-card shadow="never" :body-style="{position:'relative'}" style="margin-top:16px;">
      <div slot="header" style="position: relative;">
        <hdq-section title="核查记录" more-text="" />
      </div>

      <el-table :data="list" tooltip-effect="dark" style="width: 100%;margin-top:10px;">
        <!-- <el-table-column label="姓名" show-overflow-tooltip width="100">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="手机号" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.phone }}</template>
        </el-table-column>
        <el-table-column label="身份证号" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.idNumber }}</template>
        </el-table-column> -->
        <el-table-column label="核查日期" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.createdAt |timeFormat('yyyy-mm-dd hh:MM')  }}</template>
        </el-table-column>
        <el-table-column label="状态" show-overflow-tooltip width="500">
          <template slot-scope="scope">
            <span v-if="(nowTime - scope.row.createdAt) < limit">
              <el-link type="success" v-if="scope.row.success" @click="()=>successClick(scope)">查询成功（请于{{nowTime |showtime(scope.row.createdAt + limit) }}内点击查看报告）</el-link>
              <el-link type="danger" v-else @click="()=>successClick(scope)">查询失败（点击查看原因）</el-link>
            </span>
            <el-link type="info" v-else>核查记录已过期（有效期为{{limit/60}}分钟）</el-link>
          </template>
        </el-table-column>
      </el-table>
      <hdq-pagination small ref="pagination" @change="paginationChange" />

    </el-card>
  </div>
</template>
 <script>
import hdqPagination from "@/components/hdqPagination.vue";
export default {
  components: {
    hdqPagination
  },
  data () {
    return {
      list: [],
      limit: 600,
      nowTime: Date.parse(new Date()).toString().substr(0, 10),
      text: ''
    };
  },
  mounted () {
    this.getVerificationList();
    let timer = setInterval(() => {
      this.nowTime = Date.parse(new Date()).toString().substr(0, 10);
    }, 500);
    this.$once('hook:beforeDestroy', function () {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    });
  },
  filters: {
    showtime (nowtime, endtime) {
      var lefttime = (endtime * 1000) - (nowtime * 1000),  //距离结束时间的毫秒数
        leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)),  //计算天数
        lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24),  //计算小时数
        leftm = Math.floor(lefttime / (1000 * 60) % 60),  //计算分钟数
        lefts = Math.floor(lefttime / 1000 % 60);  //计算秒数
      return (leftd && (leftd + "天")) + (lefth && (lefth + "小时")) + (leftm && (leftm + "分钟")) + lefts + '秒';  //返回倒计时的字符串
    }
  },
  methods: {
    async getVerificationList (current = 1, size = 15) {
      console.log(size)
      const { status, data } = await this.$api.getVerificationList({ current, size })
      if (status === 200) {
        const { records } = data
        this.list = records
        this.$refs.pagination.createPagination(data)
      }
    },
    paginationChange ({ current, size }) {
      this.getVerificationList(current, size)
    },
    successClick (scope) {
      const { row: { success, message, fileUrl } } = scope
      if (success) {
        this.$router.push({
          path: '/inspect/inspectResult',
          query: {
            fileUrl: fileUrl
          }
        })
      } else {
        this.$alert(message, '失败原因', {
          confirmButtonText: '确定',
          type: 'error'
        });
      }

    }
  },
};
 </script>
 <style lang='scss' scoped>
.inspect {
  .top {
    height: 240px;
    background: #fff;
    background-image: url(~@/static/image/inspect_bg.png);
    background-repeat: no-repeat;
    background-size: auto 240px;
    background-position: center;
    position: relative;
    .btn {
      position: absolute;
      background: #0062ff;
      border-radius: 4px;
      width: 400px;
      height: 40px;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 40px;

      color: #ffffff;
      text-align: center;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 25px;
      cursor: pointer;
    }
  }
  .operate {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #262626;
    line-height: 20px;
    cursor: pointer;
    span:last-child {
      color: #999;
    }
  }
}
</style>