/* 
 * @Author: 曹俊杰 
 * @Date: 2022-02-22 18:40:32
 * @Module: 精准取证
 */
 <template>
  <div class="accurateEvidence">
    <el-card shadow="never">
      <div slot="header">
        <hdq-section title="取证方式" more-text="" />
      </div>
      <div class="evidenceType">
        <evidence-type />
      </div>
    </el-card>
    <el-card shadow="never" style="margin-top:14px;">
      <div slot="header">
        <hdq-section title="取证记录" more-text="" />
      </div>
      <hdqForm :showLabel="['type','channel','date']" @search="search" ref="hdqForm" />
      <hdqList ref="hdqList" :tableData="tableData" @operate="operate" :showLabel="['operate','note','name','type','channel','cost','recordAt','dirName','size','certificateUrl']" />
      <hdqPagination layout="prev, pager, next,sizes" ref="pagination" @change="paginationChange" />
    </el-card>
    <fileSelect @callback="fileSelectCallback" ref="fileSelect" />
  </div>
</template>
 <script>
import evidenceType from "@/components/evidenceType.vue"
import hdqList from "@/components/hdqList.vue"
import hdqForm from "@/components/hdqForm.vue"
import hdqPagination from "@/components/hdqPagination.vue"
import fileSelect from "@/components/fileSelect.vue"
export default {
  components: {
    evidenceType,
    hdqList,
    hdqForm,
    hdqPagination,
    fileSelect
  },
  data () {
    return {
      tableData: [],
      operateId: []
    };
  },
  mounted () {
    this.getEvidenceList()
  },
  methods: {
    search () {
      this.$refs.pagination.createPagination({ current: 1 })
      this.getEvidenceList()
    },
    paginationChange () {
      this.getEvidenceList()
    },
    async getEvidenceList () {
      const { current, size } = this.$refs.pagination
      const formData = this.$refs.hdqForm.getForm();
      const { data, status } = await this.$api.getEvidenceList({ current, size, ...formData })
      if (status == 200) {
        this.tableData = data.records
        this.$refs.pagination.createPagination(data)
      }
    },
    // 列表操作
    operate ({ type, data, data: { row: { docUuid, url, name } } }) {
      console.log(data)
      if (type === 'file') {
        this.$refs.fileSelect.show();
        console.log(docUuid)
        this.operateId = [docUuid]
      } else if (type === 'note') {
        this.putEvidenceList(data)
      } else if (type === 'preview') {
        this.$utils.open(this.$oss + url)
      } else if (type === 'download') {
        this.$utils.download(this.$oss + url, name)
      }
    },
    //  修改备注
    putEvidenceList ({ row, newNote }) {
      const { uuid } = row
      this.$api.putEvidenceList({ uuid: uuid, note: newNote }).then(({ status, info }) => {
        if (status === 200) {
          const index = this.tableData.findIndex(res => res.uuid === uuid)
          this.tableData[index].note = newNote;
          this.$message({
            type: 'success',
            message: info
          });
        } else {
          this.$message({
            type: 'error',
            message: info
          });
        }
      })
    },
    //  归档选择回调
    fileSelectCallback (node) {
      const { data } = node
      this.$confirm(`选择归档目录为${data.label}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(this.operateId)
        this.$api.putArchiveDocArvhive({
          parentUuid: data.uuid,
          uuidList: this.operateId
        }).then(({ status, info }) => {
          if (status === 200) {
            this.$message({
              type: 'success',
              message: info
            });
            this.$refs.fileSelect.hidden();
            this.getEvidenceList(this.$route.params)
          } else {
            this.$message({
              type: 'error',
              message: info
            });
          }
        })
      })
    },
  },
};
 </script>
 <style lang='scss' scoped>
</style>