/* 
 * @Author: 曹俊杰 
 * @Date: 2022-04-13 20:49:56
 * @Module: 合同文件
 */
import api from '@/utils/api'
const state = {

}
const mutations = {
	setHelpList (state, payload) {
		state.helpList = payload
	}
}
const actions = {
	async getFile (_, { callback, fileId }) {
		const { status, data } = await api.getFile({ fileId })
		if (status === 200) {
			// window.location.href = data.downloadUrl;
			// window.open(data.downloadUrl)
			callback && callback(data)
		}
	},
	async getDocFile (_, { callback, uuid }) {
		const { status, data } = await api.getDocFile({ uuid })
		if (status === 200) {
			callback && callback(data)
		}
	}

}
export default {
	namespaced: true,
	state,
	actions,
	mutations
}