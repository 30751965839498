/* 
 * @Author: 曹俊杰 
 * @Date: 2022-05-06 15:18:30
 * @Module: 客服
 */
 <template>
  <div>

    <ul class="service">
      <li class="service-item" v-popover="item.key" v-for="item in list" :key="item.key">
        <i :class="item.icon"></i>
      </li>
    </ul>
    <el-popover ref="kefu" placement="left" width="200" trigger="hover">
      <div style="width:176px;height:176px;" class="code"></div>
    </el-popover>
    <el-popover ref="phone" placement="left" trigger="hover">
      <p style="text-align: center;">15037788414</p>
    </el-popover>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      list: [
        {
          icon: 'el-icon-service',
          key: 'kefu',
          title: '客服'
        },
        {
          icon: 'el-icon-phone-outline',
          key: 'phone',
          title: '电话'
        }
      ]
    };
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
@keyframes slide-down {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0px);
  }
}
.code {
  background-image: url(~@/static/code.jpg);
  background-size: 100%;
}
.service {
  animation: slide-down 0.3s ease-in-out;
  width: 60px;
  position: fixed;
  z-index: 99;
  right: 35px;
  bottom: 30px;

  &-item {
    height: 65px;
    background-color: rgba(91, 121, 154, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
    border-bottom: 1px solid #fff;
    &:last-child {
      border: none;
    }
    &:hover {
      background-color: rgba(91, 121, 154, 1);
    }
    i {
      color: #fff;
      font-size: 35px;
    }
  }
}
</style>