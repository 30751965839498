/* 
 * @Author: 曹俊杰 
 * @Date: 2022-02-24 18:55:34
 * @Module: 档案列表
 */
 <template>
  <div class="hdqFileList">
    <el-input prefix-icon="el-icon-search" clearable size="mini" placeholder="搜索档案关键词" v-model="search" style="margin-bottom:10px;" />
    <div style="display: flex;justify-content: flex-end;">
      <el-button size="mini" type="text" @click="addClass">添加分类</el-button>
    </div>
    <el-tree :current-node-key="$route.params.id" highlight-current :data="data" :allow-drop="allowDrop" :expand-on-click-node="expandOnClickNode" node-key="uuid" draggable @node-click="nodeClick" default-expand-all @node-drop="nodeDrop" :filter-node-method="filterNode" ref="tree">
      <span class="hdqFileList-node" slot-scope="{ node,data }">
        <span v-if="data.uuid=='expansion'" class="expansion">
          展示更多
          <span class="el-icon-arrow-down"></span>
        </span>

        <span v-else-if="data.uuid=='received'">
          <i class="el-icon-ali-dian1" style="color:#67C23A;margin-right: 5px;"></i>
          {{data.label}}
        </span>
        <span v-else-if="data.uuid=='0'">
          <i class="el-icon-ali-dian1" style="color:#F56C6C;margin-right: 5px;"></i>
          {{data.label}}
        </span>

        <div v-else class="node">
          <div class="name" v-if="data.level==1 ">
            <span class="class">{{ node.label }}{{data.size?('('+data.size+')'):''}}</span>
          </div>

          <div class="name" v-else-if="data.level==2 ">
            <span class="file"> <span class="el-icon-ali-guidang" style="margin-right:6px;font-size:12px;"></span>{{ node.label }}{{data.size?('('+data.size+')'):''}}</span>
            <span v-if="showShare">
              <span v-if="data.shared" class="share sharing">
                <!-- <i class="el-icon-ali-fenxiang"></i> -->
                <span>分享中</span>
              </span>
              <!-- <span v-else class="share" @click.stop="()=>share(node)">
                <i class="el-icon-ali-fenxiang"></i>
                <span>分享</span>
              </span> -->
            </span>
          </div>

          <div class="name" v-else-if="data.level==3 ">
            <span class="file2"> <span class="el-icon-ali-guidang" style="margin-right:6px;font-size:12px;"></span>{{ node.label }}{{data.size?('('+data.size+')'):''}}</span>
          </div>
          <div v-else class="name">
            <span>{{ node.label }}{{data.size?('('+data.size+')'):''}}</span>
          </div>
          <el-dropdown @command="command" trigger="click" @click.stop.native>
            <span class="el-icon-ali-biaogeweibucaidan_"></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="node.level===2" :command="{node,type:'shareFile'}">分享档案</el-dropdown-item>
              <el-dropdown-item v-if="node.level===1" :command="{node,type:'addFile'}">添加档案</el-dropdown-item>
              <el-dropdown-item v-if="node.level===2" :command="{node,type:'addFile'}">添加文件夹</el-dropdown-item>
              <el-dropdown-item v-if="node.level===1" :command="{node,type:'deleteClass'}">删除分类</el-dropdown-item>
              <el-dropdown-item v-if="node.level===2" :command="{node,type:'deleteFile'}">删除档案</el-dropdown-item>
              <el-dropdown-item v-if="node.level===3" :command="{node,type:'deleteFile'}">删除文件夹</el-dropdown-item>
              <el-dropdown-item v-if="node.level===1" :command="{node,type:'changeClass'}">重命名分类</el-dropdown-item>
              <el-dropdown-item v-if="node.level===2" :command="{node,type:'changeFile'}">重命名档案</el-dropdown-item>
              <el-dropdown-item v-if="node.level===3" :command="{node,type:'changeFile'}">重命名文件夹</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </span>
    </el-tree>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    expandOnClickNode: {
      type: Boolean,
      default: true
    },
    showShare: {
      type: Boolean,
      default: false
    },
    docUuid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      data: [],
      search: '',
      allData: []
    }
  },
  mounted () {
    this.getArchiveList()
  },
  watch: {
    search (val) {
      if (val) {
        this.data = this.allData;
      } else {
        this.data = this.spliceData(this.allData)
      }
      this.$nextTick(() => {
        this.$refs.tree.filter(val);
      })
    }
  },
  methods: {
    // 节点点击
    nodeClick (_, node) {
      if (node.data.uuid === 'expansion') {
        this.expansion(node)
      } else {
        this.$emit("callback", node)
      }
    },
    // 搜索筛选
    filterNode (value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 添加分类
    addClass () {
      this.$prompt('请输入分类名称', '添加分类', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '请输入分类名称'
      }).then(({ value }) => {
        this.$api.putArchiveDir({ label: value }).then(({ data, info, status }) => {
          if (status === 200) {
            this.$message({
              type: 'success',
              message: info
            })
            const newChild = { uuid: data.uuid, label: value, children: [], level: 1 };
            this.data.push(newChild)
          } else {
            this.$message({
              type: 'error',
              message: info
            })
          }
        })
      }).catch((res) => {
        this.$message({
          type: 'info',
          message: '取消添加分类'
        });
      });
    },
    // 添加档案
    addFile (node) {
      const text = this.textRender(node)
      this.$prompt(`请输入${text}名称`, `${text}名称`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: `请输入${text}名称`
      }).then(({ value }) => {
        this.$api.putArchiveDir({ label: value, parentUuid: node.data.uuid }).then(({ data, info, status }) => {
          if (status === 200) {
            this.$message({
              type: 'success',
              message: info
            })
            const newChild = { uuid: data.uuid, label: value, level: node.level + 1 };
            this.$refs.tree.append(newChild, node)
          } else {
            this.$message({
              type: 'error',
              message: info
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: `取消添加${text}`
        });
      });
    },
    // 删除分类
    deleteClass (node) {
      const { data } = node
      if (data.children && data.children.length != 0) {
        this.$alert('删除分类时，分类必须为空。', '提示', {
          type: 'error',
          confirmButtonText: '确定'
        });
      } else {
        this.$confirm('此操作将删除该分类, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.deleteArchiveDir({ dirId: data.uuid }).then(({ info, status }) => {
            if (status === 200) {
              this.$message({
                type: 'success',
                message: info
              })
              this.$refs.tree.remove(node)
            } else {
              this.$message({
                type: 'error',
                message: info
              })
            }
          })
        }).catch((res) => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      }
    },
    // 删除档案
    deleteFile (node) {
      const { data } = node
      const text = this.textRender(node)
      if (data.children && data.children.length > 0) {
        this.$alert(`删除${text}时，${text}必须为空。`, '提示', {
          type: 'error',
          confirmButtonText: '确定'
        });
      } else {
        this.$confirm(`此操作将删除该${text}, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.deleteArchiveDir({ dirId: node.data.uuid }).then(({ info, status }) => {
            if (status === 200) {
              this.$message({
                type: 'success',
                message: info
              })
              this.$refs.tree.remove(node)
            } else {
              this.$message({
                type: 'error',
                message: info
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }

    },
    // 修改分类
    changeClass (node) {
      this.$prompt('请输入分类名称', '修改分类', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '请输入分类名称'
      }).then(({ value }) => {
        this.$api.putChangeArchiveDir({ dirId: node.data.uuid, label: value }).then(({ info, status }) => {
          if (status === 200) {
            this.$message({
              type: 'success',
              message: info
            })
            this.data[this.data.findIndex(item => item.uuid === node.data.uuid)].label = value
          } else {
            this.$message({
              type: 'error',
              message: info
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消修改分类'
        });
      });
    },
    // 修改档案
    changeFile (node) {
      const text = this.textRender(node)
      this.$prompt(`请输入${text}名称`, '修改档案', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: `请输入${text}名称`
      }).then(({ value }) => {
        this.$api.putChangeArchiveDir({ dirId: node.data.uuid, label: value }).then(({ info, status }) => {
          if (status === 200) {
            this.$message({
              type: 'success',
              message: info
            })
            const { parent: { data: { uuid: parentUuid } }, data: { uuid } } = node;
            let newData = JSON.parse(JSON.stringify(node.parent.data)).children;
            const index = newData.findIndex(item => item.uuid === uuid)
            newData[index].label = value
            this.$refs.tree.updateKeyChildren(parentUuid, newData)
          } else {
            this.$message({
              type: 'error',
              message: info
            })
          }
        })
      }).catch((res) => {
        this.$message({
          type: 'info',
          message: `取消修改${text}`
        });
      });
    },
    // 判断能否被拖拽
    allowDrop (draggingNode, dropNode, type) {
      const isReceived = (item) => {
        if (item.data.uuid == 'received') {
          return true
        } else {
          if (item.parent) {
            return isReceived(item.parent)
          } else {
            return false
          }
        }
      }
      console.log(draggingNode)
      if (isReceived(draggingNode)) {
        if (isReceived(dropNode)) {
          return type === 'inner' && draggingNode.data.level > 1 && (dropNode.data.level === 1 || dropNode.data.level === 2) && dropNode.data.uuid !== 0
        } else {
          return false
        }
      } else {
        if (isReceived(dropNode)) {
          return false
        } else {
          return (type === 'inner') && (draggingNode.data.level > 1) && (dropNode.data.level === 1 || dropNode.data.level === 2) && (dropNode.data.uuid !== 0)
        }
      }
    },
    // 拖拽成功
    nodeDrop ({ data: sun }, { data: father }) {
      this.$api.putChangeArchiveDir({ dirId: sun.uuid, parentUuid: father.uuid }).then(({ info, status }) => {
        if (status === 200) {
          this.$message({
            type: 'success',
            message: info
          })
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
      })
    },
    // 操作
    command ({ node, type }) {
      // addFile  添加档案
      // deleteClass  删除分类
      // deleteFile  删除档案
      // changeClass  修改分类
      // changeFile  修改档案
      // shareFile 分享档案
      if (type == 'addFile') {
        this.addFile(node)
      } else if (type == 'deleteClass') {
        this.deleteClass(node)
      } else if (type == 'deleteFile') {
        this.deleteFile(node)
      } else if (type == 'changeClass') {
        this.changeClass(node)
      } else if (type == 'changeFile') {
        this.changeFile(node)
      } else if (type == 'shareFile') {
        this.share(node)
      }
    },
    async getArchiveList () {
      let params = {};
      console.log(this.docUuid)
      if (this.docUuid) {
        params.docUuid = this.docUuid
      }
      const { data, status, info } = await this.$api.getArchiveList(params)
      if (status === 200) {
        this.allData = data;
        this.data = this.spliceData(data)
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
    // 截取data
    spliceData (list) {
      let data = JSON.parse(JSON.stringify(list))
      data.forEach((item, index) => {
        if (item.children && item.children.length > 10) {
          const all = data[index].children.splice(10);
          data[index].children.push({
            uuid: 'expansion',
            label: '展示更多',
            level: 2,
            all
          })
        }
      });
      return data
    },
    // 展示更多
    expansion (node) {
      node.data.all.forEach(item => {
        this.$refs.tree.append(item, node.parent)
      })
      this.$refs.tree.remove(node)
    },
    textRender ({ level }) {
      if (level == 2) {
        return '档案'
      } else if (level == 3) {
        return '文件夹'
      }

    },
    share (node) {
      this.$emit("share", node)
    },
    shareSuccess (node, change = true) {
      const { parent: { data: { uuid: parentUuid } }, data: { uuid } } = node;
      let newData = JSON.parse(JSON.stringify(node.parent.data)).children;
      const index = newData.findIndex(item => item.uuid === uuid)
      newData[index].shared = change;
      this.$refs.tree.updateKeyChildren(parentUuid, newData)
    }
  },
};
 </script>
 <style lang='scss' scoped>
@import "~@/assets/style/var.scss";
.hdqFileList {
  &-node {
    width: calc(100% - 36px);
    font-size: 14px;
    .el-icon-ali-dian1 {
      color: $--color-primary;
    }
    .node {
      display: flex;
      align-items: center;
    }
    .name {
      flex: 1;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis; /* for Opera */
      align-items: center;
      .class {
        background: rgba($color: $--color-primary, $alpha: 1);
        color: #fff;
        font-size: 12px;
        line-height: 20px;
        border-radius: 4px;
        padding: 0 4px;
      }
      .file {
        color: $--color-primary;
        font-size: 12px;
        line-height: 20px;
      }
      .file2 {
        color: #e6a23c;
        font-size: 12px;
        line-height: 20px;
      }
    }
    .share {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 12px;
      background: #f7f8f9;
      border-radius: 2px;
      color: #999999;
      cursor: pointer;
      margin-left: 8px;
      padding: 0 4px;
      vertical-align: middle;
      i {
        transform: scale(0.7);
        vertical-align: middle;
      }
    }
    .sharing {
      color: #67c23a;
      background: rgba($color: #67c23a, $alpha: 0.1);
    }
    .el-icon-ali-biaogeweibucaidan_ {
      // color: dodgerblue;
      font-size: 12px;
    }
    .expansion {
      font-size: 12px;
      color: #999;
    }
  }
}
</style>