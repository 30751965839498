/**
 * 验证电子邮箱格式
 */
function email (value) {
	return /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(value)
}

/**
 * 验证手机格式
 */
function mobile (value) {
	return /^1[23456789]\d{9}$/.test(value)
}

/**
 * 验证URL格式
 */
function url (value) {
	return /^((https|http|ftp|rtsp|mms):\/\/)(([0-9a-zA-Z_!~*'().&=+$%-]+: )?[0-9a-zA-Z_!~*'().&=+$%-]+@)?(([0-9]{1,3}.){3}[0-9]{1,3}|([0-9a-zA-Z_!~*'()-]+.)*([0-9a-zA-Z][0-9a-zA-Z-]{0,61})?[0-9a-zA-Z].[a-zA-Z]{2,6})(:[0-9]{1,4})?((\/?)|(\/[0-9a-zA-Z_!~*'().;?:@&=+$,%#-]+)+\/?)$/
		.test(value)
}

/**
 * 验证日期格式
 */
function date (value) {
	if (!value) return false
	// 判断是否数值或者字符串数值(意味着为时间戳)，转为数值，否则new Date无法识别字符串时间戳
	if (number(value)) value = +value
	return !/Invalid|NaN/.test(new Date(value).toString())
}

/**
 * 验证ISO类型的日期格式
 */
function dateISO (value) {
	return /^\d{4}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$/.test(value)
}

/**
 * 验证十进制数字
 */
function number (value) {
	return /^[+-]?(\d+\.?\d*|\.\d+|\d\.\d+e\+\d+)$/.test(value)
}

/**
 * 验证字符串
 */
function string (value) {
	return typeof value === 'string'
}

/**
 * 验证整数
 */
function digits (value) {
	return /^\d+$/.test(value)
}

/**
 * 验证身份证号码
 */
function idCard (value) {
	return /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
		value
	)
}
/**
 * 严格验证身份证号码
 */
function idCardStrict (card) {
	var vcity = {
		11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古",
		21: "辽宁", 22: "吉林", 23: "黑龙江", 31: "上海", 32: "江苏",
		33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东",
		41: "河南", 42: "湖北", 43: "湖南", 44: "广东", 45: "广西",
		46: "海南", 50: "重庆", 51: "四川", 52: "贵州", 53: "云南",
		54: "西藏", 61: "陕西", 62: "甘肃", 63: "青海", 64: "宁夏",
		65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外"
	},


		//检查号码是否符合规范，包括长度，类型
		isCardNo = function (card) {
			//身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
			var reg = /(^\d{15}$)|(^\d{17}(\d|X|x)$)/;
			if (reg.test(card) === false) {
				return false;
			}
			return true;
		},


		//取身份证前两位,校验省份
		checkProvince = function (card) {
			var province = card.substr(0, 2);
			if (vcity[province] == undefined) {
				return false;
			}
			return true;
		},


		//检查生日是否正确
		checkBirthday = function (card) {
			var len = card.length;
			//身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
			if (len == '15') {
				let re_fifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/;
				let arr_data = card.match(re_fifteen);
				let year = arr_data[2];
				let month = arr_data[3];
				let day = arr_data[4];
				let birthday = new Date('19' + year + '/' + month + '/' + day);
				return verifyBirthday('19' + year, month, day, birthday);
			} else if (len == '18') {
				//身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X

				let re_eighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9Xx])$/;
				let arr_data = card.match(re_eighteen);
				let year = arr_data[2];
				let month = arr_data[3];
				let day = arr_data[4];
				let birthday = new Date(year + '/' + month + '/' + day);
				return verifyBirthday(year, month, day, birthday);
			} else {
				return false;
			}


		},


		//校验日期
		verifyBirthday = function (year, month, day, birthday) {
			var now = new Date();
			var now_year = now.getFullYear();
			//年月日是否合理
			if (birthday.getFullYear() == year && (birthday.getMonth() + 1) == parseInt(month) && ((birthday.getDate() + 1) == parseInt(day) || (birthday.getDate()) == parseInt(day))) {
				//判断年份的范围（3岁到100岁之间)
				var time = now_year - year;
				/*--------------------------*/
				if (time >= 0) {
					return true;
				}
				return false;
			}
			return false;
		},




		//15位转18位身份证号
		changeFivteenToEighteen = function (card) {
			if (card.length == '15') {
				var arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
				var arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
				var cardTemp = 0, i;
				card = card.substr(0, 6) + '19' + card.substr(6, card.length - 6);
				for (i = 0; i < 17; i++) {
					cardTemp += card.substr(i, 1) * arrInt[i];
				}
				card += arrCh[cardTemp % 11];
				return card;
			}
			return card;
		},


		//校验位的检测
		checkParity = function (card) {
			//15位转18位
			card = changeFivteenToEighteen(card);
			var len = card.length;
			if (len == '18') {
				var arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
				var arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
				var cardTemp = 0, i, valnum;
				for (i = 0; i < 17; i++) {
					cardTemp += card.substr(i, 1) * arrInt[i];
				}
				valnum = arrCh[cardTemp % 11];
				if (valnum == card.substr(17, 1)) {
					return true;
				}
				return false;
			}
			return false;
		};

	//是否为空
	if (!card) {
		return false;
	}
	card = card.toUpperCase();
	//校验长度，类型
	if (!isCardNo(card)) {
		return false;
	}
	//检查省份
	if (!checkProvince(card)) {
		return false;
	}
	//校验生日
	if (!checkBirthday(card)) {
		return false;
	}
	//检验位的检测
	if (!checkParity(card)) {
		return false;
	}
	return true;
}
// function idCardStrict (idCard) {
// 	var flag = false;
// 	//15位和18位身份证号码的正则表达式
// 	var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;

// 	//如果通过该验证，说明身份证格式正确，但准确性还需计算
// 	if (regIdCard.test(idCard)) {
// 		if (idCard.length == 18) {
// 			var idCardWi = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); //将前17位加权因子保存在数组里
// 			var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); //这是除以11后，可能产生的11位余数、验证码，也保存成数组
// 			var idCardWiSum = 0; //用来保存前17位各自乖以加权因子后的总和
// 			for (var i = 0; i < 17; i++) {
// 				idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i];
// 			}

// 			var idCardMod = idCardWiSum % 11;//计算出校验码所在数组的位置
// 			var idCardLast = idCard.substring(17);//得到最后一位身份证号码

// 			//如果等于2，则说明校验码是10，身份证号码最后一位应该是X
// 			if (idCardMod == 2) {
// 				if (idCardLast == "X" || idCardLast == "x") {
// 					flag = true;
// 				} else {
// 					flag = false;
// 				}
// 			} else {
// 				//用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
// 				if (idCardLast == idCardY[idCardMod]) {
// 					flag = true;
// 				} else {
// 					flag = false;
// 				}
// 			}
// 		} else if (idCard.length == 15) {
// 			var id17 = idCard.substring(0, 6) + '19' + idCard.substring(6);
// 			var parityBit = getParityBit(id17);
// 			var tempIdCard = id17 + parityBit;
// 			flag = validateIdCard(tempIdCard);
// 		}
// 	} else {
// 		flag = false;
// 	}
// 	return flag;
// }

/**
 * 是否车牌号
 */
function carNo (value) {
	// 新能源车牌
	const xreg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/
	// 旧车牌
	const creg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/
	if (value.length === 7) {
		return creg.test(value)
	} if (value.length === 8) {
		return xreg.test(value)
	}
	return false
}

/**
 * 金额,只允许2位小数
 */
function amount (value) {
	// 金额，只允许保留两位小数
	return /^[1-9]\d*(,\d{3})*(\.\d{1,2})?$|^0\.\d{1,2}$/.test(value)
}

/**
 * 中文
 */
function chinese (value) {
	const reg = /^[\u4e00-\u9fa5]+$/gi
	return reg.test(value)
}

/**
 * 只能输入字母
 */
function letter (value) {
	return /^[a-zA-Z]*$/.test(value)
}

/**
 * 只能是字母或者数字
 */
function enOrNum (value) {
	// 英文或者数字
	const reg = /^[0-9a-zA-Z]*$/g
	return reg.test(value)
}

/**
 * 验证是否包含某个值
 */
function contains (value, param) {
	return value.indexOf(param) >= 0
}

/**
 * 验证一个值范围[min, max]
 */
function range (value, param) {
	return value >= param[0] && value <= param[1]
}

/**
 * 验证一个长度范围[min, max]
 */
function rangeLength (value, param) {
	return value.length >= param[0] && value.length <= param[1]
}

/**
 * 是否固定电话
 */
function landline (value) {
	const reg = /^\d{3,4}-\d{7,8}(-\d{3,4})?$/
	return reg.test(value)
}

/**
 * 判断是否为空
 */
function empty (value) {
	switch (typeof value) {
		case 'undefined':
			return true
		case 'string':
			if (value.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length == 0) return true
			break
		case 'boolean':
			if (!value) return true
			break
		case 'number':
			if (value === 0 || isNaN(value)) return true
			break
		case 'object':
			if (value === null || value.length === 0) return true
			for (const i in value) {
				return false
			}
			return true
	}
	return false
}

/**
 * 是否json字符串
 */
function jsonString (value) {
	if (typeof value === 'string') {
		try {
			const obj = JSON.parse(value)
			if (typeof obj === 'object' && obj) {
				return true
			}
			return false
		} catch (e) {
			return false
		}
	}
	return false
}

/**
 * 是否数组
 */
function array (value) {
	if (typeof Array.isArray === 'function') {
		return Array.isArray(value)
	}
	return Object.prototype.toString.call(value) === '[object Array]'
}

/**
 * 是否对象
 */
function object (value) {
	return Object.prototype.toString.call(value) === '[object Object]'
}

/**
 * 是否短信验证码
 */
function code (value, len = 6) {
	return new RegExp(`^\\d{${len}}$`).test(value)
}

/**
 * 是否函数方法
 * @param {Object} value
 */
function func (value) {
	return typeof value === 'function'
}

/**
 * 是否promise对象
 * @param {Object} value
 */
function promise (value) {
	return object(value) && func(value.then) && func(value.catch)
}

/** 是否图片格式
 * @param {Object} value
 */
function image (value) {
	const IMAGE_REGEXP = /\.(jpeg|jpg|gif|png|svg|webp|jfif|bmp|dpg)/i
	return IMAGE_REGEXP.test(value)
}

/**
 * 是否视频格式
 * @param {Object} value
 */
function video (value) {
	const VIDEO_REGEXP = /\.(mp4|mpg|mpeg|dat|asf|avi|rm|rmvb|mov|wmv|flv|mkv)/i
	return VIDEO_REGEXP.test(value)
}

/**
 * 是否为正则对象
 * @param {Object}
 * @return {Boolean}
 */
function regExp (o) {
	return o && Object.prototype.toString.call(o) === '[object RegExp]'
}

export default {
	email,
	mobile,
	url,
	date,
	dateISO,
	number,
	digits,
	idCard,
	idCardStrict,
	carNo,
	amount,
	chinese,
	letter,
	enOrNum,
	contains,
	range,
	rangeLength,
	empty,
	isEmpty: empty,
	jsonString,
	landline,
	object,
	array,
	code,
	func,
	promise,
	video,
	image,
	regExp,
	string
}
