/* 
 * @Author: 曹俊杰 
 * @Date: 2023-01-05 19:35:34
 * @Module: 公共头部
 */
 <template>
  <div class="header">
    <slot></slot>
    <div class="title">
      {{$route.meta.title}}
    </div>
    <div class="content">
      <div class="l" v-if="!user.name">
        <i class="el-icon-plus"></i>
        <span class="pointer" style="margin-left:6px;" @click="()=>$store.dispatch('user/toAccountAuth')">个人认证</span>
      </div>
      <div class="solid" v-if="!user.name"></div>
      <div class="c">
        <span class="pointer" style="color:#0062FF" v-if="!user.company" @click="()=>$store.dispatch('user/toAccountCompanyAuth')">加入/创建企业</span>
        <span v-else>{{user.company}}</span>

        <span style="color:#EEEEEE">｜</span>
        <span style="color:#262626" v-if="!user.company">个人</span>
        <span v-else>
          <span v-if="user.creator" style="color:#FF8412">法人</span>
          <span v-else style="color:#0062FF">
            员工
          </span>
        </span>
        <el-dropdown @command="headerClick" placement="bottom-start" size="small">
          <span style="color:#666666;margin-left:10px;" class="el-icon-ali-qiehuan"></span>
          <el-dropdown-menu slot="dropdown">

            <el-dropdown-item v-for="item in userLogList" :key="item.uuid" :command="item">
              <div class="dropdownItem" style="display:flex;align-items: center;">
                <div class="dropdownItem-content">
                  <p>
                    <span v-if="item.company">{{item.company}}</span>
                    <span v-else>暂无公司</span>
                  </p>
                  <p>
                    <span v-if="item.name">{{item.name}}</span>
                    <span v-else style="color:#FF0D0D">未认证</span>
                    <span style="margin-left:6px;">{{item.phone}}</span>
                    <span style="color:#262626;margin:6px" v-if="!item.company">个人</span>
                    <span v-else style="margin:6px">
                      <span v-if="item.creator" style="color:#FF8412">法人</span>
                      <span v-else style="color:#0062FF">员工</span>
                    </span>
                    <i class="el-icon-delete" style="color:#FF0D0D" @click.stop="()=>userDelete(item)"></i>
                  </p>
                </div>
                <div class="dropdownItem-active el-icon-check" v-show="item.uuid==user.uuid" style="color:#0062FF;margin-left:20px;">
                </div>
              </div>

            </el-dropdown-item>
            <el-dropdown-item :command="{uuid:'add'}" divided>
              <div style="line-height:48px;text-align: center;">
                添加账号
              </div>
            </el-dropdown-item>

          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="solid"></div>
      <div class="r ">
        <el-avatar :size="32"></el-avatar>
        <div class="name">
          <p v-if="user.name">{{user.name}}</p>
          <p v-else>暂无信息</p>
        </div>
        <p class="el-icon-caret-bottom" style="size:12px;color:#D9D9D9"></p>
        <div class="headerUserInfo">
          <div class="headerUserInfo-t">
            <el-avatar :size="40"></el-avatar>
            <div class="headerUserInfo-t-content">
              <div class="username">
                <p>
                  {{user.name||'暂无信息'}}
                </p>
                <div class="icon" :class="user.name?'success':'error'"></div>
              </div>
              <div class="phone">账号：{{user.phone}}</div>
            </div>
          </div>
          <div class="headerUserInfo-c pointer" @click="$router.push({name: 'personal'})">
            <el-avatar :size="16"></el-avatar>
            <p style="margin-left:6px;">
              个人中心
            </p>
          </div>
          <div class="headerUserInfo-b pointer" @click="logout">退出登录</div>
        </div>
      </div>
    </div>

  </div>

  <!-- <div class="content">
    <div class="title">
      {{$route.meta.title}}
    </div>
    <div class="menu">
      <span v-if="user.company">
        {{user.company}}
      </span>
      <span v-else class="login" style="color:#409EFF" @click="()=>$store.dispatch('user/toAccountCompanyAuth')">加入/创建企业</span>
      <el-divider direction="vertical"></el-divider>
      <span v-if="user.token">
        <el-dropdown @command="headerClick" placement="bottom-start" size="small">
          <span>
            <span>
              {{user.nickName||user.phone}}
            </span>
            <span v-if="user.company" style="margin-left:6px;">
              <el-tag type="warning" size="mini" v-if="user.creator">法人</el-tag>
              <el-tag v-else size="mini">员工</el-tag>
            </span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in userLogList" :key="item.uuid" :command="item">
              <p class="user" :class="{'acitve':item.uuid==user.uuid}">
                <span v-if="item.company">{{item.company}}</span>
                <span v-else class="none">未实名公司</span>
              </p>
              <p :class="{'acitve':item.uuid==user.uuid}">
                {{item.phone}}
                <span v-if="item.company" style="margin:0 6px;">
                  <el-tag type="warning" size="mini" v-if="item.creator">法人</el-tag>
                  <el-tag v-else size="mini">员工</el-tag>
                </span>
                <i class="el-icon-delete" style="color:#F56C6C" @click.stop="()=>userDelete(item)"></i>
              </p>
            </el-dropdown-item>
            <el-dropdown-item :command="{uuid:'add'}" icon="el-icon-plus">添加账号</el-dropdown-item>
            <el-dropdown-item :command="{uuid:'logout'}" divided icon="el-icon-switch-button">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </span>
      <router-link v-else class="login" style="color:#409EFF" :to="{ path: '/login',query:{redirect:$route.path} }">注册/登录</router-link>
    </div>
  </div> -->
</template>
 <script>
import { mapState } from "vuex";
export default {
  components: {},
  data () {
    return {};
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      userLogList: state => state.userLog.userLogList
    })
  },
  mounted () { },
  methods: {
    // 头部菜单点击
    headerClick (item) {
      const { uuid } = item
      if (uuid === 'add') {
        this.$router.push({
          path: '/login'
        })
      } else {
        this.$store.dispatch('user/changeUserInfo', item)
      }
    },
    logout () {
      this.$confirm(`确定要退出当前账号“${this.user.nickName || this.user.phone}”？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.commit("user/logout")
        this.$store.dispatch("userLog/clearUserLog")
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
      });
    },
    userDelete (item) {
      if (item.uuid == this.user.uuid) {
        this.$alert('不能删除当前登录用户，请切换其他账号再删除。', '提示', {
          confirmButtonText: '确定',
          type: 'error'
        });
      } else {
        this.$store.dispatch("userLog/deleteUserLog", item)
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
.header {
  height: 70px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
  width: 100%;
  .title {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #262626;
  }
  .content {
    display: flex;
    align-items: center;
    .solid {
      width: 1px;
      height: 10px;
      background: #eeeeee;
      margin: 0 20px;
    }
    .l {
      width: 94px;
      height: 32px;
      background: #0062ff;
      border: 1px solid #0062ff;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Source Han Sans CN";
      font-size: 14px;
      color: #ffffff;
    }
    .c {
      line-height: 32px;
      background: #f7f8f9;
      border-radius: 2px;
      padding: 0 12px;
      text-align: center;
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
    .r {
      display: flex;
      align-items: center;
      height: 70px;
      .name {
        margin: 0 10px;
        font-size: 14px;
        line-height: 21px;
        color: #000000;
      }
      &:hover {
        .headerUserInfo {
          display: block;
        }
      }
      .headerUserInfo {
        width: 240px;
        height: 170px;
        background: #fff;
        position: fixed;
        top: 70px;
        z-index: 1000;
        right: 20px;
        display: none;
        &:hover {
          display: block;
        }
        &-t {
          display: flex;
          height: 74px;
          padding: 0 16px;
          align-items: center;
          &-content {
            margin-left: 12px;
            .username {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #262626;
              display: flex;
              align-items: center;
              .icon {
                width: 60px;
                height: 20px;
                margin-left: 6px;
              }
              .error {
                background-image: url(~@/static/image/weirenzheng.png);
                background-size: 100%;
              }
              .success {
                background-image: url(~@/static/image/yirenzheng.png);
                background-size: 100%;
              }
            }
            .phone {
              font-family: "Source Han Sans CN";
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #999999;
              margin-top: 4.5px;
            }
          }
        }
        &-c {
          display: flex;
          align-items: center;
          height: 48px;
          padding: 0 16px;
          font-family: "Source Han Sans CN";
          font-size: 14px;
          line-height: 21px;
          color: #262626;
        }
        &-b {
          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 48px;
          color: #666666;
          text-align: center;
          &:hover {
            color: #0062ff;
          }
        }
      }
    }
  }
}
</style>