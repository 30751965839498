const platformList = [
	{
		value: '1',
		label: '抖音'
	},
	{
		value: '2',
		label: '快手'
	},
	{
		value: '3',
		label: 'YY'
	},
	{
		value: '4',
		label: '虎牙'
	},
	{
		value: '5',
		label: '微信视频号直播'
	},
	{
		value: '6',
		label: '网易CC'
	},
	{
		value: '7',
		label: '网易look'
	},
	{
		value: '8',
		label: '斗鱼直播'
	},
	{
		value: '9',
		label: '映客直播'
	},
	{
		value: '10',
		label: '花椒直播'
	},
	{
		value: '11',
		label: '陌陌直播'
	},
	{
		value: '12',
		label: '探探直播'
	},
	{
		value: '13',
		label: '哔哩哔哩直播'
	},
	{
		value: '14',
		label: '繁星'
	},

]
export default platformList