/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-04 19:03:53
 * @Module: 查询结果图片预览
 */
 <template>
  <div>
    <div class="btn" v-show="btnShow">
      <el-button type="primary" plain v-popover:popover>点击联系客服保护权益</el-button>
    </div>
    <el-popover ref="popover" placement="right" width="200" trigger="click">
      <div style="width:176px;height:176px;" class="serviceCode "></div>
    </el-popover>
  </div>
</template>
 <script>
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
Vue.use(VueViewer)
export default {
  components: {
  },
  data () {
    return {
      imageList: [],
      title: [],
      btnShow: false
    };
  },
  mounted () { },
  methods: {
    show ({ full, index }) {
      this.btnShow = true
      const imageList = full.map(item => item.image);
      const title = full.map((item, index) => `第${index + 1}/${full.length}张`)
      this.imageList = imageList;
      this.title = title;

      const renderTitle = (alt) => {
        const index = imageList.findIndex(item => item.indexOf(alt) > 0);
        return title[index]
      }
      this.$viewerApi({
        options: {
          initialViewIndex: index,
          title: [true, (image, imageData, index) => {
            return renderTitle(image.alt)
          }],
          hide: () => {
            this.btnShow = false
          },
          zIndex: 999
        },
        images: imageList
      })
    }
  }
};
 </script>
 <style lang='scss' scoped>
.btn {
  width: 140px;
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 100px;
  right: 0;
  margin: auto;
}
.serviceCode {
  background-image: url(~@/static/service.jpg);
  background-size: 100%;
}
</style>