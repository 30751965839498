/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-02 14:40:41
 * @Module: 归档管理详情
 */
 <template >
  <div class="ArchiveManagementDetail">
    <hdqForm v-show="showSelection" :showLabel="['category','type','channel','date']" @search="search" ref="hdqForm" />
    <div>
      <hdqList :showSelection="showSelection" ref="hdqList" :spanMethod="spanMethod" :tableData="tableData" @operate="operate" :showLabel="showSelection?['label','category','evidenceType','evidenceChannel','startAt','endAt','parentLabel','note','operate']:['label','category','evidenceType','evidenceChannel','startAt','endAt','parentLabel','note']" />
    </div>
    <div style="margin-top:20px;" v-if="showSelection">
      <el-button size="mini" round @click="multipleFile">归档</el-button>
      <el-button size="mini" round @click="multipleDownload">下载</el-button>
    </div>
    <fileSelect @callback="fileSelectCallback" ref="fileSelect" />
  </div>
</template>
 <script>
import hdqList from "@/components/hdqList.vue"
import hdqForm from "@/components/hdqForm.vue"
import fileSelect from "@/components/fileSelect.vue"
export default {
  props: {
    showSelection: {
      type: Boolean,
      default: true
    }
  },
  components: {
    hdqList,
    hdqForm,
    fileSelect
  },
  data () {
    return {
      tableData: [],
      operateId: []
    };
  },
  mounted () {
  },
  methods: {
    search (res) {
      this.postArchiveNodeList(this.$route.params)
    },
    // 多选归档
    multipleFile () {
      this.$refs.fileSelect.show();
      this.operateId = this.$refs.hdqList.$refs.multipleTable.selection.map(res => res.uuid);
    },
    // 多选下载
    multipleDownload () {

    },
    // 列表操作
    operate ({ type, data, data: { row: { uuid, url, name, label } } }) {
      console.log(label)
      if (type === 'file') {
        this.$refs.fileSelect.show(uuid);
        this.operateId = [uuid]
      } else if (type === 'note') {
        this.putArchiveDoc(data)
      } else if (type === 'preview') {
        this.$store.dispatch('documents/getDocFile', {
          uuid,
          callback: ({ url }) => {
            this.$utils.open(url)
          }
        })
      } else if (type === 'download') {
        this.$store.dispatch('documents/getDocFile', {
          uuid,
          callback: ({ url }) => {
            this.$utils.download(url, label)
          }
        })
      }
    },
    putArchiveDoc ({ row, newNote }) {
      const { uuid } = row
      this.$api.putArchiveDoc({ docId: uuid, note: newNote }).then(({ status, info }) => {
        if (status === 200) {
          const index = this.tableData.findIndex(res => res.uuid === uuid)
          this.tableData[index].note = newNote;
          this.$message({
            type: 'success',
            message: info
          });
        } else {
          this.$message({
            type: 'error',
            message: info
          });
        }
      })
    },
    //  归档选择回调
    fileSelectCallback ({ data }) {
      this.$confirm(`选择归档目录为${data.label}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.putArchiveDocArvhive({
          parentUuid: data.uuid,
          uuidList: this.operateId
        }).then(({ status, info }) => {
          if (status === 200) {
            this.$message({
              type: 'success',
              message: info
            });
            this.$refs.fileSelect.hidden();
            this.postArchiveNodeList(this.$route.params)
          } else {
            this.$message({
              type: 'error',
              message: info
            });
          }
        })

      })
    },
    async postArchiveNodeList ({ id: dirUuid = 0 }) {
      const params = this.$refs.hdqForm.getForm();
      if (dirUuid === 'received') {
        const { status, data, info } = await this.$api.getArchiveDirReceivedList(params)
        if (status === 200) {
          this.tableData = data
        } else {
          this.$message({
            type: 'error',
            message: info
          });
        }
      } else if (dirUuid == 0) {
        const { status, data, info } = await this.$api.postArchiveDirList(params)
        if (status === 200) {
          this.tableData = data.records
        } else {
          this.$message({
            type: 'error',
            message: info
          });
        }
      } else {
        if (dirUuid != 0) {
          params.dirUuid = dirUuid
        }
        const { status, data, info } = await this.$api.postArchiveNodeList(params)
        if (status === 200) {
          this.tableData = data.records
        } else {
          this.$message({
            type: 'error',
            message: info
          });
        }
      }

    },
    spanMethod ({ row, column, rowIndex, columnIndex }) {
      if (row.type === "DIR") {
        if (columnIndex == 0) {
          return [1, 1]
        } else if (columnIndex == 1) {
          return [1, 100]
        } else {
          return []
        }

      } else {
        return [1, 1]
      }
    }
  },
  watch: {
    "$route.params": {
      handler (params) {
        this.$nextTick(() => {
          this.postArchiveNodeList(params)
        })
      },
      immediate: true
    }
  }
};
 </script>
 <style lang='scss' scoped>
.ArchiveManagementDetail {
  padding: 0px 24px;
  padding-bottom: 24px;
}
</style>