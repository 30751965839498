/* 
 * @Author: 曹俊杰 
 * @Date: 2022-05-31 14:05:46
 * @Module: 公司授权提示
 */
 <template>
  <div>
    <el-alert v-show="!hasAuth" style="margin-bottom:10px;" :closable="false" type="error">
      <span slot="title">您的公司授权已过期，此页面功能将限制使用，
        <el-link type="primary" @click="postCompanyAuthorize">请法人点击重新授权</el-link>。
      </span>
    </el-alert>
    <qrDialog ref="qrDialog" @close="qrDialogClose">
      <template>
        <span class="text">
          <span>请法人用</span>
          <span>支付宝APP</span>
          <span>扫码并按照提示完成签署</span>
        </span>
      </template>
    </qrDialog>
  </div>
</template>
 <script>
import { mapState } from "vuex";
import qrDialog from "@/components/qrDialog.vue"
let js;
export default {
  components: {
    qrDialog
  },
  props: {
    hasAuth: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      // hasAuth: true,  // 公司授权是否可用
    };
  },
  mounted () {
    this.getCompanyAuthStatus()
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    })
  },
  methods: {
    async getCompanyAuthStatus () {
      const { status, data } = await this.$api.getCompanyAuthStatus({ companyUuid: this.user.companyUuid });
      if (status === 200) {
        this.$emit("update:hasAuth", data.hasAuth);
        // this.hasAuth = data.hasAuth;
      }
    },
    // 公司授权
    async postCompanyAuthorize () {
      const { data } = await this.$api.postCompanyAuthorize();
      const { authUrl, taskUuid } = data;
      this.$refs.qrDialog.show(authUrl);
      js = setInterval(async () => {
        const { data, status } = await this.$api.getCompanyAuthTask({ taskUuid });
        if (status == 200 && data.success) {
          this.$refs.qrDialog.hidden();
          clearInterval(js);
          this.getCompanyAuthStatus();
        }
      }, 1000)
    },
    qrDialogClose () {
      js && clearInterval(js);
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>