/* 
 * @Author: 曹俊杰 
 * @Date: 2022-05-13 19:20:18
 * @Module: 用户的历史记录
 */

const state = {
	userLogList: []
}
const mutations = {
	setUserLog (state, payload) {
		state.userLogList = payload
	}

}
const actions = {
	saveUserLog ({ state }, payload) {
		const index = state.userLogList.findIndex(res => res.uuid === payload.uuid);
		if (index > -1) {
			state.userLogList[index] = { ...state.userLogList[index], ...payload };
		} else {
			state.userLogList.push(payload)
		}
	},
	clearUserLog ({ commit }) {
		commit('setUserLog', [])
	},
	deleteUserLog ({ state, commit }, payload) {
		let list = state.userLogList
		const index = list.findIndex(item => item.uuid == payload.uuid)
		list.splice(index, 1)
		commit('setUserLog', list)
	}
}
export default {
	namespaced: true,
	state,
	actions,
	mutations
}