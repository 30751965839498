/* 
 * @Author: 曹俊杰 
 * @Date: 2022-06-08 10:24:17
 * @Module: 曝光平台
 */
 <template>
  <div>

    <div class="exposure">
      <div class="top">
        <div class="nav">
          <div :class="{active:$route.name==item.key}" class="nav-item pointer" @click="()=>$router.push({name:item.key})" v-for="item in navList" :key="item.key">{{item.title}}</div>
        </div>

        <div style="display: flex;align-items: center;">
          <el-button @click="$router.push({path:'/exposure/form'})" type="primary" size="small" style="margin-left:20px;">我要曝光</el-button>
        </div>
      </div>
      <router-view />
    </div>
    <p class="footer">声明：曝光信息来自用户自主分享上传，不保证其真实性，仅作为参考！</p>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      navList: [
        {
          title: '违约列表',
          key: 'all'
        },
        {
          title: '我的曝光',
          key: 'mine'
        }
      ]
    };
  },
  mounted () {
    console.log(this.$route)
  },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.exposure {
  width: 100%;
  background: #ffffff;
  padding-top: 10px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  .top {
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    border-bottom: #e3e3e3 1px solid;
    .nav {
      height: 50px;
      display: flex;
      align-items: center;

      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      &-item {
        margin-right: 40px;
        line-height: 50px;
        border-bottom: 2px rgba($color: #000000, $alpha: 0) solid;
      }
      .active {
        color: #0062ff;
        border-bottom: 2px #0062ff solid;
        line-height: 50px;
      }
    }
    .btn {
      width: 110px;
      height: 40px;
      background: #0062ff;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
    }
  }
}
.footer {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  text-align: center;
}
</style>