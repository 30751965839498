/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-18 15:19:32
 * @Module: 好多签单元格
 */
 <template>
  <div class="hdqCell-item" @click="handleClick">
    <div class="title"> <i class="el-icon-ali-dian1" style="color:#E3E3E3;margin-right:6px; "></i>{{data.title}}</div>
    <div class="icon">
      <div class="top" v-if="data.top">顶置</div>
    </div>
    <div class="date">{{data.createdAt|timeFormat('mm-dd')}}</div>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({
        title: '',
        top: false,
        createdAt: 0
      })
    }

  },
  data () {
    return {};
  },
  mounted () { },
  methods: {
    handleClick (evt) {
      this.$emit('click', evt);
    }
  },
};
 </script>
 <style lang='scss' scoped>
.hdqCell-item {
  display: flex;
  height: 40px;
  align-items: center;
  &:hover {
    .title {
      color: #0062ff;
    }
    .date {
      color: #0062ff;
    }
  }
  .title {
    flex: 1;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis; /* for Opera */
    cursor: pointer;
  }
  .icon {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .top {
      background: #ff8d1a;
      font-size: 12px;
      color: #fff;
      padding: 4px 6px;
      border-radius: 4px;
      transform: scale(0.8);
    }
  }
  .date {
    width: 40px;
    font-size: 12px;
    color: #8590a6;
    text-align: right;
  }
}
</style>