import Router from '@/router'
import api from '@/utils/api'
import { MessageBox, Message } from 'element-ui';
const state = {
	user: {
		auth: false,
		createdAt: "",
		deletedAt: null,
		nickName: null,
		phone: "",
		token: "",
		updatedAt: "",
		uuid: "",
		companyUuid: '',
		company: '',
		companyIdNumber: '',
		representativeName: '',
		representativePhone: '',
		representativeIdNumber: '',
		canTakeEvidence: '',
		searchConformed: false,
		inviteCode: null
	},
	// companyList: [],
	balance: 0,
	beans: 0
}
const mutations = {
	setUserInfo (state, payload) {
		state.user = {
			...state.user,
			...payload
		};
		// state.commit("userLog/saveUserLog", state.user)
	},
	setData (state, { key, value }) {
		state[key] = value
	},
	logout (state) {
		state.user = {
			auth: false,
			createdAt: "",
			deletedAt: null,
			nickName: null,
			phone: "",
			token: "",
			updatedAt: "",
			uuid: "",
			companyUuid: '',
			company: '',
			companyIdNumber: '',
			representativeName: '',
			representativePhone: '',
			representativeIdNumber: '',
			canTakeEvidence: '',
			searchConformed: false,
			inviteCode: null
		}
		state.balance = 0;
		state.beans = 0;
		Router.push({
			path: '/login', query: { redirect: Router.path }
		})
		window.clearVuexAlong();
	}

}
const actions = {
	async getAccount ({ commit }, callback) {
		const { status, data: { token, ...data } } = await api.getAccount()
		if (status === 200) {
			if (data.companyUuid) {
				commit('setUserInfo', data)
			}
			callback && callback(data)
		}
	},
	async getCompanyBalance ({ commit, state }) {
		const { status, data } = await api.getCompanyBalance({ companyUuid: state.user.companyUuid })
		if (status === 200) {
			commit('setData', { key: 'balance', value: data.coins });
			commit('setData', { key: 'beans', value: data.beans });
		}
	},
	async getSearchConformed ({ commit, state }, { inviteCode, callback }) {
		const { status, info } = await api.putSearchConform({ companyUuid: state.user.companyUuid, inviteCode })
		if (status === 200) {
			Message({
				type: 'success',
				message: info
			})
			commit('setUserInfo', {
				searchConformed: true
			})
			callback && callback({ status, info })
		} else {
			Message({
				type: 'error',
				message: info
			})
		}

	},
	// // 获取我的公司列表
	// async getCompanyMine ({ commit, state }) {
	// 	const { status, data } = await api.getCompanyMine()
	// 	if (status === 200) {
	// 		commit('setData', { key: 'companyList', value: data.list });
	// 	}
	// },
	// 切换用户
	changeUserInfo ({ dispatch, commit }, params) {
		commit('setUserInfo', params);
		location.reload();
		// dispatch('getCompanyBalance');
	},
	toAccountAuth () {
		Router.push({ name: 'UserAuth' })
	},
	toAccountCompanyAuth ({ state, dispatch }) {
		const { name } = state.user
		if (name) {
			Router.push({ name: 'CompanyAuth' })
		} else {
			MessageBox.confirm('企业实名前先进行个人实名, 是否继续?', '提示', {
				confirmButtonText: '去个人实名',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				dispatch('toAccountAuth')
			})
		}
	},
	setUserInfo ({ commit, state, dispatch }, params) {
		commit('setUserInfo', params);
		dispatch('userLog/saveUserLog', state.user, { root: true })
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations
}