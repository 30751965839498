/* 
 * @Author: 曹俊杰 
 * @Date: 2022-04-27 16:43:27
 * @Module: 档案选择
 */
 <template>
  <div class="fileSelect">
    <el-dialog title="选择目录" v-if="dialogVisible" destroy-on-close :visible.sync="dialogVisible" width="30%">
      <div class="content">
        <hdq-file-list ref="hdqFileList" :docUuid="docUuid" :expandOnClickNode="false" @callback="callback" />
      </div>
    </el-dialog>
  </div>
</template>
 <script>
import hdqFileList from "@/components/hdqFileList.vue"
export default {
  components: {
    hdqFileList
  },
  data () {
    return {
      dialogVisible: false,
      docUuid: ''
    };
  },
  mounted () { },
  methods: {
    callback (node) {
      const { data } = node
      if (data.level == 1) {
        // 如果是一级目录，打开目录
        let nodes = this.$refs.hdqFileList.$refs.tree.store.nodesMap;
        for (var i in nodes) {
          if (nodes[i].data.uuid == data.uuid) {
            nodes[i].expanded = !nodes[i].expanded;
          }
        }
      } else {
        this.$emit('callback', node)
      }
    },
    show (docUuid) {
      console.log(docUuid)
      this.dialogVisible = true;
      this.docUuid = docUuid
    },
    hidden () {
      this.dialogVisible = false
    }
  },
};
 </script>
 <style lang='scss' scoped>
.fileSelect {
  .content {
    height: 300px;
    overflow-x: auto;
  }
}
</style>