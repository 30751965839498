/* 
 * @Author: 曹俊杰 
 * @Date: 2022-05-31 15:54:50
 * @Module: 个人授权查询 （此用户查询为了弥补之前用户授权问题）
 */
 <template>
  <div class="authorizedUser">
    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :visible="(!user.auth)?false:user.isCandidate?false:true" width="30%">
      <el-result icon="warning" title="个人授权系统升级">
        <span slot="subTitle">请重新授权，否则将影响系统的正常使用。

        </span>
        <template slot="extra">
          <el-button type="primary" plain @click="loginOut" size="medium">退出登录</el-button>
          <el-button type="primary" @click="postAccountAuthorize" size="medium">重新授权</el-button>
        </template>
      </el-result>

    </el-dialog>
    <!-- <el-alert v-show="(!user.auth)?false:user.isCandidate?false:true" style="margin-bottom:10px;" :closable="false" type="error">
      <span slot="title">个人授权系统升级，请重新授权，否则将影响系统的正常使用，
        <el-link type="primary" @click="postAccountAuthorize">点击此处重新授权</el-link>。
      </span>
    </el-alert> -->
    <qrDialog ref="qrDialog" @close="qrDialogClose">
      <template>
        <span class="text">
          <span>请用户
            <span style="color:#0062ff">{{user.name}}</span>
            用
          </span>
          <span>支付宝APP</span>
          <span>扫码并按照提示完成授权</span>
        </span>
      </template>
    </qrDialog>
  </div>
</template>
 <script>
import { mapState } from "vuex";
import qrDialog from "@/components/qrDialog.vue"
let js;
export default {
  components: {
    qrDialog
  },
  data () {
    return {
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    })
  },
  mounted () {
  },
  methods: {
    // 个人授权
    async postAccountAuthorize () {
      const { data } = await this.$api.postAccountAuthorize();
      const { authUrl, taskUuid } = data;
      this.$refs.qrDialog.show(authUrl);
      js = setInterval(async () => {
        const { data, status } = await this.$api.getCompanyAuthTask({ taskUuid });
        if (status == 200 && data.success) {
          this.$refs.qrDialog.hidden();
          clearInterval(js);
          this.$store.dispatch('user/getAccount')
        }
      }, 1000)
    },
    qrDialogClose () {
      js && clearInterval(js);
    },
    loginOut () {
      this.$confirm('如果退出登录，则将继续影响当前登录账号的正常使用, 是否退出?', '请重新授权', {
        confirmButtonText: '确定退出登录',
        cancelButtonText: '返回授权',
        type: 'warning'
      }).then(() => {
        this.$store.commit("user/logout")
        this.$store.dispatch("userLog/clearUserLog")
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
      }).catch(() => ({}));
    }
  },
};
 </script>
 <style lang='scss' scoped>
.authorizedUser /deep/.el-dialog__header {
  padding: 0;
}
</style>