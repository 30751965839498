/* 
 * @Author: 曹俊杰 
 * @Date: 2023年5月4日
 * @Module: 筛选表单
 */
 <template>
  <el-form :inline="true" label-suffix=":" :model="searchForm" size="mini" style="margin-top:20px;">
    <el-row gutter="20">
      <el-col :span="4">
        <el-form-item >
          <el-input prefix-icon="el-icon-search" style="width:250px;" v-model="searchForm.keyword" clearable placeholder="请输入要查找的合同名称或文件名"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='4'>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">搜索</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
 <script>
export default {
  components: {
  },
  props: {
    searchForm: {
      type: Object,
      default: () => ({
        keyword: ''
      })
    }
  },
  data () {
    return {
    };
  },
  mounted () { },
  methods: {
    onSubmit () {
      this.$emit('submit')
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>