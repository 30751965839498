/* 
 * @Author: 曹俊杰 
 * @Date: 2022-02-22 18:44:20
 * @Module: 账号管理
 */
<template>
  <div class="AccountManagement">
    <div class="tabs border">
      <div class="tabs-item pointer" :class="{active:$route.name=='personal'}" @click="$router.push({path:'/accountManagement/personal'})">
        个人信息
      </div>
      <div class="tabs-item pointer" :class="{active:$route.name=='sealManagement'}" @click="$router.push({path:'/accountManagement/sealManagement'})">
        我的印章
      </div>
      <div class="solid " :class="$route.name=='personal'?'solid-ac1':'solid-ac2'"></div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
</script>
<style lang='scss' scoped>
.AccountManagement {
  .tabs {
    position: relative;
    display: flex;
    border-bottom: 1px solid #eee;
    &-item {
      width: 104px;
      line-height: 56px;
      text-align: center;
    }
    .solid {
      width: 30px;
      height: 2px;
      background: #0062ff;
      border-radius: 2px;
      position: absolute;
      bottom: -1px;
      transition: 0.5s all;
      &-ac1 {
        left: 37px;
      }
      &-ac2 {
        left: 141px;
      }
    }
    .active {
      color: #0062ff;
    }
  }
}
</style>
