/* 
 * @Author: 曹俊杰 
 * @Date: 2022-02-22 18:41:38
 * @Module: 归档管理
 */
 <template>
  <div class="archiveManagement">
    <div class="title" style="">
      <div class="active">档案目录</div>
      <div @click="$router.push({path:'/archiveShare'})">分享列表</div>
    </div>
    <el-row>
      <el-col :span="5">
        <div class="left">
          <hdq-file-list showShare @callback="callback" @share="share" ref="hdqFileList" />
        </div>
      </el-col>
      <el-col :span="19" class="asd">
        <hdqSection title="档案详情" more-text="" />
        <div style="height:10px"></div>
        <router-view></router-view>
      </el-col>
    </el-row>
    <personnelSelection ref="personnelSelection" @submit="submit" :visible.sync="visible" />
  </div>
</template>
 <script>
import hdqFileList from "@/components/hdqFileList.vue"
import personnelSelection from "@/components/personnelSelection.vue"
export default {
  components: {
    hdqFileList,
    personnelSelection
  },
  data () {
    return {
      userIds: [],
      dirUuid: "",
      node: {},
      visible: false
    };
  },
  mounted () {
  },
  watch: {
    dialogVisible (res) {
      if (!res) {
        this.userIds = [];
        this.node = {};
        this.loading = false;
        this.dirUuid = "";
      }
    }
  },
  methods: {
    // 归档目录的点击回调
    callback ({ data: { uuid, level } }) {
      if (level === 2 || uuid == 0 || level === 3 || level === 1) {
        this.$router.push({ path: `/archiveManagement/${uuid}` })
      }
    },
    // 选择分享文件的回调
    share (node) {
      const { data: { shared, uuid, label } } = node;
      this.dirUuid = uuid;
      this.node = node;
      // 判断当前的状态
      // if (shared) {
      //   // this.$confirm(`此操作将取消分享档案《${label}》, 是否继续?`, '提示', {
      //   //   confirmButtonText: '确定',
      //   //   cancelButtonText: '取消',
      //   //   type: 'warning'
      //   // }).then(() => {
      //   //   this.ondelete(uuid);
      //   // }).catch(() => ({}));
      //   console.log("取消分享")
      // } else {
      // }
      this.visible = true;
    },
    // 选择接受法务的提交
    async submit (arr) {
      const { userIds, dirUuid } = this
      const { status, info } = await this.$api.putShare({
        receiverUuids: arr.map(item => item.empUuid), dirUuid
      });
      if (status === 200) {
        this.$refs.hdqFileList.shareSuccess(this.node);
        this.$message({
          type: "success",
          message: info
        })
        this.visible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
    // 取消分享
    async ondelete (shareUuid) {
      const { status, info } = await this.$api.deleteShare({ shareUuid });
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$refs.hdqFileList.shareSuccess(this.node, false);
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
.title {
  height: 50px;
  background: #ffffff;
  width: 100%;
  margin-top: 10px;
  border-bottom: 1px solid #e3e3e3;
  padding-left: 24px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 50px;
  display: flex;
  .active {
    color: #0062ff;
    border-bottom: 2px #0062ff solid;
    font-family: PingFangSC-Medium, PingFang SC;
  }
  div {
    cursor: pointer;
  }
  div:last-child {
    margin-left: 40px;
  }
}
.archiveManagement {
  background: #fff;
  border: #ffffff 1px solid;
  & /deep/ .hdqSection {
    margin-top: 0px;
  }
  .left {
    min-height: calc(100vh - 120px - 28px - 51px);
    padding: 19px 24px;
    border-right: 1px solid #e3e3e3;
  }
}
</style>