/* 
 * @Author: 曹俊杰 
 * @Date: 2022-02-22 18:42:43
 * @Module: 案件进度
 */
 <template>
  <div class="caseProgress">案件进度</div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
</style>