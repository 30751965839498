/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-02 18:19:03
 * @Module: 取证类型
 */
 <template>
  <div class="evidenceType">
    <div class="card" v-for="item in AccurateEvidenceList" :key="item.key" @click="()=>open(item)">
      <div :class="item.icon"></div>
      <p>{{item.title}}</p>
    </div>
    <!-- 精准取证开通账户 -->
    <el-dialog :visible.sync="dialogVisible" width="500px" :show-close="false" @close="close">
      <div class="content">
        <div class="title">
          <h1>如何开通取证功能</h1>
          <p>如已注册过取证账号，则忽略本文全部内容！</p>
        </div>
        <div class="item">
          <div class="item-title">
            <span class="num">1</span>
            <h2>账号注册</h2>
          </div>
          <div class="item-content">
            访问网页<a target="_blank" :href="isDev?`https://test.ip360.org/niceToSingLogin?invitationCode=A8&thirdUserIdentity=${user.uuid}&phone=${user.phone}`:`https://www.ip360.net.cn/niceToSingLogin?invitationCode=A5&thirdUserIdentity=${user.uuid}&phone=${user.phone}`">www.ip360.net</a> （点击后打开新标签页），完成账号注册后，关闭该注册网页。
            <b>提示：强烈建议，APP注册手机号与本系统登录手机号一致！</b>
          </div>
        </div>
        <div class="item" style="margin-top:20px;" v-show="user.canTakeEvidence">
          <div class="item-title">
            <span class="num">2</span>
            <h2>下载取证APP</h2>
          </div>
          <div class="item-content">
            手机扫码下载取证APP，并用刚才注册的账号登录。
          </div>
          <div class="code"></div>
        </div>
        <div class="item" style="margin-top:20px;" v-show="user.canTakeEvidence">
          <div class="item-title">
            <span class="num">3</span>
            <h2>完成取证</h2>
          </div>
          <div class="item-content">
            用取证app进行取证后的证据文件，会自动同步到本系统。
            <b>提示：目前仅同步拍照取证、录音取证、录像取证、通话取证、手机录屏取证</b>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
 <script>
import { mapState } from "vuex";
let js;
export default {
  components: {},
  data () {
    return {
      AccurateEvidenceList: [
        {
          title: '拍照取证',
          color: '#FA525C',
          key: 'pzqz',
          icon: 'el-icon-ali-paizhaoquzheng'
        },
        {
          title: '录音取证',
          color: '#FAAF25',
          key: 'lyqz',
          icon: 'el-icon-ali-luyinquzheng'
        },
        {
          title: '录像取证',
          color: '#27C2A9',
          key: 'lxqz',
          icon: 'el-icon-ali-luxiangquzheng'
        },
        {
          title: '通话取证',
          color: '#8185A6',
          key: 'thqz',
          icon: 'el-icon-ali-tonghuaquzheng'
        },
        {
          title: '手机录屏取证',
          color: '#05A9EB',
          key: 'sjlpqz',
          icon: 'el-icon-ali-shoujilupingquzheng'
        },
        {
          title: 'PC自主上传',
          color: '#0563FA',
          key: 'pczzsc',
          icon: 'el-icon-ali-PCzizhushangchuan'
        }

      ],
      dialogVisible: false
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    isDev () {
      return process.env.VUE_APP_DEV === 'true'
    }
  },
  mounted () { },
  methods: {
    open ({ key }) {
      if (key === 'pczzsc') {
        this.$router.push({
          name: 'PCupload'
        })
      } else {
        this.dialogVisible = true;
        js = setInterval(() => {
          this.$store.dispatch('user/getAccount', res => {
            if (res.canTakeEvidence) {
              clearInterval(js);
              this.$store.dispatch('user/setUserInfo', res)
              this.$message({
                type: 'success',
                message: '账号已成功注册'
              })
            }
          })
        }, 1000);
      }
    },
    close () {
      js && clearInterval(js);
    }
  },
};
 </script>
 <style lang='scss' scoped>
.evidenceType {
  display: flex;
  flex-wrap: wrap;
  .card {
    background: #f7f8f9;
    width: calc((100% - 80px) / 6);
    margin-right: 16px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #666666;
    cursor: pointer;
    p {
      margin-top: 7px;
    }
    &:nth-child(6n) {
      margin-right: 0px;
    }
  }
  & /deep/ .el-dialog {
    &__header,
    &__body {
      padding: 0px !important;
    }
  }
  .content {
    // min-height: 592px;
    background: #fff;
    background-image: url(~@/static/image/evidenceType_bg.png);
    background-size: 592px auto;
    background-repeat: no-repeat;
    background-position: top right;
    padding-bottom: 24px;
    .title {
      height: 76px;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1 {
        font-size: 18px;
        font-weight: 500;
        color: #262626;
        line-height: 25px;
        font-family: PingFangSC-Medium, PingFang SC;
      }
      p {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff0000;
        line-height: 22px;
        margin-top: 4px;
      }
    }
    .item {
      padding: 0 24px;
      &-title {
        .num {
          background: rgba($color: #005eff, $alpha: 0.05);
          color: #005eff;
          width: 17px;
          line-height: 17px;
          text-align: center;
          display: inline-block;
          border-radius: 17px;
          margin-right: 6px;
        }
        h2 {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          display: inline;
        }
      }
      &-content {
        padding-left: 23px;
        margin-top: 6px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        a {
          color: #0062ff;
        }
        b {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 400;
          color: #262626;
          line-height: 26px;
          display: block;
        }
      }
      .code {
        width: 130px;
        height: 130px;
        // background: #005eff;
        margin: 0 auto;
        margin-top: 10px;
        background-image: url(~@/static/code2.png);
        background-size: 100% auto;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>
