/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-02 17:58:45
 * @Module: 好多签列表
 */
 <template>
  <div class="hdqList">
    <el-table size="mini" :span-method="spanMethod" current-row-key="uuid" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="45" :selectable="selectable" v-if="showSelection">
      </el-table-column>
      <el-table-column label="文件名" show-overflow-tooltip v-if="showLabel.includes('label')||showLabel.includes('name')">
        <template slot-scope="scope">
          <div v-if="scope.row.type === 'DIR'">
            <el-link @click="()=>todetail(scope.row)" icon="el-icon-folder" :type="scope.row.level==2?'primary':'warning'" :underline="false">{{ scope.row.label ||scope.row.name }}</el-link>
          </div>
          <div v-else>
            {{ scope.row.label ||scope.row.name ||'--' }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="证书" width="70" v-if="showLabel.includes('certificateUrl')">
        <template slot-scope="scope">
          <span class="primary pointer" v-if="scope.row.certificateUrl" @click="()=>$utils.open($oss+scope.row.certificateUrl)">查看证书</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="分类" width="70" v-if="showLabel.includes('category')">
        <template slot-scope="scope">
          <span style="color:#0062FF" v-if="scope.row.category==='CONTRACT'">合同</span>
          <span v-if="scope.row.category==='EVIDENCE'" style="color:#FF8412">证据</span>
        </template>
      </el-table-column>

      <el-table-column label="取证类型" width="70" v-if="showLabel.includes('type')||showLabel.includes('evidenceType')">
        <template slot-scope="scope">
          <span>{{ (scope.row.evidenceType||scope.row.type) | evidenceType }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="channel" label="取证方式" width="70" v-if="showLabel.includes('channel')||showLabel.includes('evidenceChannel')">
        <template slot-scope="scope">
          <span v-if="scope.row.channel||scope.row.evidenceChannel">{{ scope.row.channel||scope.row.evidenceChannel | evidenceChannel }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>

      <el-table-column label="效力" width="65" v-if="showLabel.includes('channel')||showLabel.includes('evidenceChannel')">
        <template slot-scope="scope">
          <span :style="scope.row.channel==='UPLOAD'||scope.row.evidenceChannel==='UPLOAD'?'color:#FF0D0D;':'color:#00B457'">{{ scope.row.channel==='UPLOAD'||scope.row.evidenceChannel==='UPLOAD'?'待定':'有效' }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="cost" label="消耗金币" width="80" v-if="showLabel.includes('cost')">
        <template slot-scope="scope">
          <span v-if="scope.row.cost" style="color:#FF8412;">{{ scope.row.cost }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="文件大小" width="80" v-if="showLabel.includes('size')">
        <template slot-scope="scope">
          <span v-if="scope.row.size">{{ scope.row.size|formatBytes }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>

      <el-table-column label="时间" width="110" show-overflow-tooltip v-if="showLabel.includes('startAt')">
        <template slot-scope="scope">
          <div v-if="scope.row.endAt" class="flex">
            <img src="@/static/image/accurateEvidence_qu.png">
            <p>{{ scope.row.endAt|timeFormat }}</p>
          </div>
          <div v-else-if="scope.row.startAt" class="flex">
            <img src="@/static/image/electronicDocuments_shi.png">
            <p>{{ scope.row.startAt|timeFormat }}</p>
          </div>
          <span v-else>--</span>
        </template>
      </el-table-column>

      <!-- <el-table-column label="完成时间" width="100" show-overflow-tooltip v-if="showLabel.includes('endAt')">
        <template slot-scope="scope">
          <span v-if="scope.row.endAt">{{ scope.row.endAt|timeFormat }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column> -->
      <el-table-column label="取证时间" width="112" v-if="showLabel.includes('recordAt')">
        <template slot-scope="scope">
          <div v-if="scope.row.recordAt" class="flex">
            <img src="@/static/image/accurateEvidence_qu.png">
            <p>{{ scope.row.recordAt|timeFormat }}</p>
          </div>
          <span v-else>--</span>
        </template>
      </el-table-column>

      <el-table-column label="归档" width="120" v-if="showLabel.includes('parentLabel')||showLabel.includes('dirName')">
        <template slot-scope="scope">
          <span>
            <i class="el-icon-ali-guidang pointer" style="font-size:12px;margin-right:4px;" @click="()=>operate({type:'file',data:scope})"></i>
            <span>{{ scope.row.parentLabel||scope.row.dirName||'未归档' }}</span>
          </span>
        </template>
      </el-table-column>

      <el-table-column label="备注" width="50" show-overflow-tooltip v-if="showLabel.includes('note')">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" :content="scope.row.note" placement="top" :disabled="!scope.row.note">
            <span @click="()=>open(scope)" class="el-icon-ali-xingzhuangjiehe1" :style="scope.row.note?'':'color:#999;'">
            </span>
          </el-tooltip>

        </template>
      </el-table-column>

      <el-table-column label="操作" width="100" :resizable="false" v-if="showLabel.includes('operate')">
        <template slot-scope="scope">
          <div style="color:#0062FF;">
            <span @click="()=>operate({type:'preview',data:scope})" class="pointer">查看</span>
            <span>｜</span>
            <span @click="()=>operate({type:'download',data:scope})" class="pointer">下载</span>
          </div>

        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    tableData: {
      type: Array,
      default: () => ([])
    },
    showLabel: {   // 隐藏字段
      type: Array,
      default: () => ([])
    },
    spanMethod: {
      type: Function,
      default: () => [1, 1]
    },
    showSelection: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      multipleSelection: [],  // 勾选列表
    };
  },
  filters: {
    evidenceType (res) {
      // OTHER-其它
      // PHOTOGRAPH-拍照取证
      // RECORD_VOICE-录音取证
      // RECORD_VIDEO-录像取证
      // RECORD_PHONE-电话录音取证
      // RECORD_SCREEN-录屏取证
      // WEB_SNAPSHOT-快照取证
      // WEB_SCREEN-网页录屏取证
      // PROCESS-过程取证
      // PHONE_ARRAY-手机阵列
      if (res === 'OTHER') {
        return '其它'
      } else if (res === 'PHOTOGRAPH') {
        return '拍照取证'
      } else if (res === 'RECORD_VOICE') {
        return '录音取证'
      } else if (res === 'RECORD_VIDEO') {
        return '录像取证'
      } else if (res === 'RECORD_PHONE') {
        return '电话录音取证'
      } else if (res === 'RECORD_SCREEN') {
        return '录屏取证'
      } else if (res === 'WEB_SNAPSHOT') {
        return '快照取证'
      } else if (res === 'WEB_SCREEN') {
        return '网页录屏取证'
      } else if (res === 'PROCESS') {
        return '过程取证'
      } else if (res === 'PHONE_ARRAY') {
        return '手机阵列'
      } else {
        return res
      }
    },
    evidenceChannel (res) {
      if (res === 'MOBILE') {
        return '手机'
      } else if (res === 'PC') {
        return '电脑'
      } else if (res === 'UPLOAD') {
        return '自主上传'
      } else {
        return res
      }
    }
  },
  mounted () { },
  methods: {
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    operate (res) {
      this.$emit('operate', res)
    },
    open (scope) {
      this.$prompt('请输入备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '请输入备注'
      }).then(({ value }) => {
        this.$emit('operate', { type: 'note', data: { ...scope, newNote: value } })

      }).catch((res) => {
        console.log(res)
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    selectable (row) {
      return row.type !== "DIR"
    },
    todetail ({ uuid }) {
      this.$router.push({ path: `/archiveManagement/${uuid}` })
    }
  },
};
 </script>
 <style lang='scss' scoped>
@import "~@/assets/style/var.scss";
.el-icon-ali-guidang,
.el-icon-ali-xingzhuangjiehe1,
.el-icon-view,
.el-icon-download {
  color: $--color-primary;
}
.flex {
  display: flex;
  align-items: center;
}
</style>