/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-03 17:20:10
 * @Module: 好多签表单筛选
 */
 <template>
  <div class="form">
    <div class="form-item" v-if="showLabel.includes('category')">
      <el-select v-model="form.category" placeholder="档案分类" size="mini" clearable>
        <el-option v-for="item in categoryList" :key="item.value" :label="item.name" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="form-item" v-if="showLabel.includes('expenseType')">
      <el-select v-model="form.expenseType" placeholder="消费类型" size="mini" clearable>
        <el-option v-for="item in [{value:'CONTRACT',label:'合同签订'},{value:'EVIDENCE',label:'精准取证'},{value:'VERIFICATION',label:'三要素核查'}]" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="form-item" v-if="showLabel.includes('coinType')">
      <el-select v-model="form.coinType" placeholder="充值类型" size="mini" clearable>
        <el-option v-for="item in [{value:'COIN',label:'金币'},{value:'BEAN',label:'卡券'}]" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="form-item" v-if="showLabel.includes('empUuid')">
      <el-select v-model="form.empUuid" placeholder="消费账号" size="mini" clearable>
        <el-option v-for="item in accountColleagueList" :key="item.uuid" :label="item.name" :value="item.uuid">
        </el-option>
      </el-select>
    </div>
    <div class="form-item" v-if="showLabel.includes('type')">
      <el-select v-model="form.type" placeholder="取证类型" size="mini" clearable>
        <el-option v-for="item in typeList" :key="item.value" :label="item.name" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="form-item" v-if="showLabel.includes('channel')">
      <el-select v-model="form.channel" placeholder="取证方式" size="mini" clearable>
        <el-option v-for="item in channelList" :key="item.value" :label="item.name" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="form-item-date" v-if="showLabel.includes('date')">
      <el-date-picker v-model="form.date" value-format="timestamp" type="daterange" size="mini" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
    </div>
    <el-button type="primary" size="mini" @click="search">搜索</el-button>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    showLabel: {   // 显示字段
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      accountColleagueList: [],
      categoryList: [],
      typeList: [],
      channelList: [],
      form: {
        category: '',  //文档分类
        type: '',   // 取证类型
        channel: '',   //取证渠道
        expenseType: '',
        empUuid: '',
        coinType: '',
        date: null
      },
    };
  },
  mounted () {
    this.getData()
  },
  methods: {
    search () {
      let data = this.getForm()
      this.$emit('search', data)
    },
    getForm () {
      let data = {}
      this.showLabel.map(res => {
        if (res == 'date') {
          if (this.form.date) {
            data.startTime = this.form.date[0] / 1000;
            data.endTime = (this.form.date[1] + 86400000) / 1000
          }
        } else {
          data[res] = this.form[res]
        }
      })
      return data
    },
    getData () {
      if (this.showLabel.includes('empUuid')) {  // 消费账号
        this.getAccountColleagueList()
      } else if (this.showLabel.includes('category') || this.showLabel.includes('type') || this.showLabel.includes('channel')) {
        this.getArchiveDocFilter()
      }
    },
    async getAccountColleagueList () {  // 获取消费账号
      const { status, data } = await this.$api.getAccountColleagueList()
      if (status === 200) {
        this.accountColleagueList = data
      }
    },
    async getArchiveDocFilter () {
      const { status, data } = await this.$api.getArchiveDocFilter()
      if (status === 200) {
        this.categoryList = data.category
        this.typeList = data.type
        this.channelList = data.channel
      }
      console.log(data)
    }
  },
};
 </script>
 <style lang='scss' scoped>
.form {
  display: flex;
  margin: 10px 0;
  &-item {
    width: 17%;
    margin-right: 10px;
    flex-shrink: 0;
    &-date {
      margin-right: 10px;
      &/deep/ .el-date-editor--daterange.el-input,
      .el-date-editor--daterange.el-input__inner,
      .el-date-editor--timerange.el-input,
      .el-date-editor--timerange.el-input__inner {
        width: 100%;
      }
    }
  }
}
</style>