import request from '@/utils/request'

const api = {
	//登陆验证码
	postLoginSmsCode (params) {
		return request.post(`/api/sms/code/login`, params)
	},
	//注册验证码
	postRegisterSmsCode (params) {
		return request.post(`/api/sms/code/register`, params)
	},
	// 登录
	postUserLogin (params) {
		return request.post(`/api/account/login`, params)
	},
	// 注册
	postUserRegister (params) {
		return request.post(`/api/account/register`, params)
	},
	//个人实名认证
	postAccountAuth (params) {
		return request.post(`/api/account/auth`, params)
	},
	//加入/创建企业
	postAccountCompanyAuth (params) {
		return request.post(`/api/company/auth`, params)
	},
	//加入/创建企业状态
	getCompanyAuthTask ({ taskUuid }) {
		return request.get(`/api/company/auth/${taskUuid}`, {}, false)
	},
	//公司余额
	getCompanyBalance ({ companyUuid, ...params }) {
		return request.get(`/api/company/${companyUuid}/balance`, params)
	},
	//修改公司邮寄地址
	putCompanyAddress ({ companyUuid, ...params }) {
		return request.put(`/api/company/${companyUuid}/address`, params)
	},
	//获取公司邮寄地址
	getCompanyAddress ({ companyUuid, ...params }) {
		return request.get(`/api/company/${companyUuid}/address`, params)
	},
	//我的公司列表
	getCompanyMine (params) {
		return request.get(`/api/company/mine`, params)
	},
	//获取公司授权状态
	getCompanyAuthStatus (params) {
		return request.get(`/api/company/${params.companyUuid}/authstatus`, params)
	},
	//公司授权
	postCompanyAuthorize (params) {
		return request.post(`/api/company/authorize`, params)
	},
	//获取签署URL
	getSignerUrl ({ uuid, ...params }) {
		return request.get(`/api/signer/${uuid}/url`, params)
	},
	//合同文件获取文件上传URL
	postfFileUpload (params) {
		return request.post(`/api/file/upload/url`, params)
	},


	//提交合同
	postContract (params) {
		return request.post(`/api/contract`, params)
	},
	//撤消合同
	deleteContract (params) {
		return request.delete(`/api/contract/${params.uuid}`)
	},
	//获取合同列表
	getContract (params) {
		return request.get(`/api/contract`, params)
	},
	//合同文件信息   获取文件url
	getFile (params) {
		return request.get(`/api/file/${params.fileId}`)
	},
	// 获取文件url
	getDocFile (params) {
		return request.get(`/api/archive/doc/${params.uuid}/url`)
	},

	// 合同统计
	getContractProfile (params) {
		return request.get(`/api/contract/profile`, params)
	},
	//获取帐号信息
	getAccount (params) {
		return request.get(`/api/account`, params, false)
	},
	//个人授权
	postAccountAuthorize (params) {
		return request.post(`/api/account/authorize`, params)
	},
	//公告列表
	getNotice (params) {
		return request.get(`/api/notice`, params)
	},
	//轮播图   label 标签：home,video
	getBannerList (params) {
		return request.get(`/api/banner`, params)
	},

	// 公告详情
	getNoticeDetail (params) {
		return request.get(`/api/notice/${params.noticeId}`)
	},
	// 法务学院
	getVideo (params) {
		return request.get(`/api/video`, params)
	},
	// 法务学院详情
	getVideoDetail (params) {
		return request.get(`/api/video/${params.videoId}`)
	},
	// 账号充值
	postRecharge (params) {
		return request.post(`/api/recharge`, { returnUrl: window.location.href, ...params })
	},
	// 消费记录
	getTransaction (params) {
		return request.get(`/api/transaction`, params)
	},
	// 验证人员信息
	getAccountValidate (params) {
		return request.get(`/api/account/validate`, params)
	},
	// 员工列表
	getAccountColleagueList (params) {
		return request.get(`/api/account/colleague`, params)
	},
	//员工列表(普通员工)
	getAccountNormalList (params) {
		return request.get(`/api/account/normal`, params)
	},
	//添加员工帐号
	putAccount (params) {
		return request.put(`/api/account/${params.empUuid}`)
	},
	//删除员工帐号
	deleteAccount (params) {
		return request.delete(`/api/account/${params.empUuid}`)
	},
	// 账号充值包列表
	postRechargePackage (params) {
		return request.get(`/api/recharge/package`, params)
	},
	// 充值记录
	getRechargeList (params) {
		return request.get(`/api/recharge`, params)
	},
	// 档案目录列表
	getArchiveList (params) {
		console.log(params)
		return request.get(`/api/archive/dir`, params)
	},
	// 新建归档目录
	putArchiveDir (params) {
		return request.put(`/api/archive/dir`, params)
	},
	// 新建归档目录
	deleteArchiveDir (params) {
		return request.delete(`/api/archive/dir/${params.dirId}`, params)
	},
	// 修改归档目录
	putChangeArchiveDir ({ dirId, ...params }) {
		return request.put(`/api/archive/dir/${dirId}`, params)
	},


	// 目录下的文件
	postArchiveDirList (params) {
		return request.get(`/api/archive/doc`, { size: 100, ...params })
	},


	// 目录下的文件  (带目录)
	postArchiveNodeList (params) {
		return request.get(`/api/archive/node`, { size: 100, ...params })
	},

	// 修改文件
	putArchiveDoc (params) {
		return request.put(`/api/archive/doc/${params.docId}`, params)
	},
	// 修改文件所属目录
	putArchiveDocArvhive (params) {
		return request.put(`/api/archive/doc/archive`, params)
	},
	// 帮助列表
	getHelpList (params) {
		return request.get(`/api/fqa`, params)
	},
	// 证据列表
	getEvidenceList (params) {
		return request.get(`/api/evidence`, params)
	},
	// 最新证据列表
	getEvidenceLatestList (params) {
		return request.get(`/api/evidence/latest`, params)
	},
	// 更新证据列表
	putEvidenceList ({ uuid, ...params }) {
		return request.put(`/api/evidence/${uuid}`, params)
	},
	// 添加证据
	postEvidenceList (params) {
		return request.post(`/api/evidence`, params)
	},
	// 筛选参数
	getArchiveDocFilter (params) {
		return request.get(`/api/archive/doc/filter`, params)
	},
	// 三要素核验 发送验证码
	postSmsCodeVerification (params) {
		return request.post(`/api/sms/code/verification`, params)
	},
	// 三要素核验
	postVerification (params) {
		return request.post(`/api/verification`, params)
	},
	//三要素核验列表
	getVerificationList (params) {
		return request.get(`/api/verification/lastest`, params)
	},
	//分享列表
	getshareList (params) {
		return request.get(`/api/share`, params)
	},
	//分享列表(分组)
	getshareGroupedList (params) {
		return request.get(`/api/share/grouped`, params)
	},
	//目录列表(分享给我的)
	getArchiveDirReceivedList (params) {
		return request.get(`/api/archive/dir/received`, params)
	},
	// 法务列表
	getUserCounsellorList (params) {
		return request.get(`/api/user/counsellor`, params)
	},
	// 印章列表
	getSealList (params) {
		return request.get(`/api/seal`, params)
	},
	// 印章授权列表
	getSealAuthList (params) {
		return request.get(`/api/seal/auth`, params)
	},
	// 我的印章授权列表
	getSealAuthMineList (params) {
		return request.get(`/api/seal/auth/mine`, params)
	},
	// 创建印章授权
	postSealAuth (params) {
		return request.post(`/api/seal/auth`, params)
	},
	// 删除印章授权
	deletetSealAuth (params) {
		return request.delete(`/api/seal/auth/${params.uuid}`, params)
	},
	// 印章授权详情
	getSealAuth (params) {
		return request.get(`/api/seal/auth/${params.authUuid}`, params, false)
	},
	// 新增分享
	putShare (params) {
		return request.put(`/api/share`, params)
	},
	// 删除分享
	deleteShare (params) {
		return request.delete(`/api/share/${params.shareUuid}`)
	},
	//删除分享(根据档案)
	deleteDirShare (params) {
		return request.delete(` /api/share/dir/${params.dirUuid}`)
	},
	//曝光列表
	getExposureList (params) {
		return request.get(`/api/exposure/latest`, params)
	},
	//添加曝光列表
	postExposure (params) {
		return request.post(`/api/exposure`, params)
	},
	//删除曝光列表
	deleteExposure (params) {
		return request.delete(`/api/exposure/${params.uuid}`)
	},
	//查小号列表
	getSearchList (params) {
		return request.get(`/api/search`, params)
	},
	//查小号城市列表
	getCitys (params) {
		return request.get(`/cityapi/v1/area/info`, params)
	},
	//新建查小号任务
	postSearch (params) {
		return request.post(`/api/search`, params)
	},
	//取消查小号任务
	putSearch (params) {
		return request.put(`/api/search/${params.uuid}`, params)
	},
	//加速查小号任务
	putSearchSpeedup (params) {
		return request.put(`/api/search/speedup`, params)
	},
	//开通查小号系统
	putSearchConform (params) {
		return request.put(`/api/search/conform`, params)
	},
	//查小号自动续费
	putSearchAutoRenew (params) {
		return request.put(` /api/search/auto-renew`, params)
	}
}
// const install = (Vue) => {

// 	Vue.prototype.$api = api
// }
export default api