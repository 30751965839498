/* 
 * @Author: 曹俊杰 
 * @Date: 2022-07-14 16:22:37
 * @Module: 广告
 */
 <template>
  <el-dialog custom-class="ad_1" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
    <!-- <span>这是一段信息</span> -->
    <div>
      <el-image style="width:100%" :src="require('@/static/image/img_1.jpg')" :preview-src-list="[require('@/static/image/img_1.jpg')]"></el-image>
      <div class="colse" @click="handleClose"><i class="el-icon-close"></i></div>
    </div>
    <!-- <img src="@/static/image/ad_1.jpg" style="width:100%" alt="" srcset=""> -->
  </el-dialog>
</template>
 <script>
export default {
  components: {},
  props: {
    // dialogVisible: {
    //   type: Boolean,
    //   default: true
    // }
  },
  data () {
    return {
      dialogVisible: false
    };
  },
  mounted () { },
  methods: {
    handleClose () {
      this.dialogVisible = false
    }
  },
};
 </script>
 <style lang='scss' scoped>
.colse {
  top: 0px;
  right: -50px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #fff;
  background-color: #606266;

  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0.8;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/deep/ {
  .ad_1 {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
      .el-image {
        vertical-align: middle;
      }
    }
  }
}
</style>


