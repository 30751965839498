/* 
 * @Author: 曹俊杰 
 * @Date: 2022-10-31 18:22:38
 * @Module: 卡券收费规则
 */
 <template>
  <div class="beanRules">
    <!-- <el-dialog :visible.sync="dialogVisible" width="500px">
      <div class="content">
        <el-image style="width: 100%;" :src="require('@/static/image/liucheng.jpg')" fit="contain"></el-image>
        <p> 本功能依托于“网红头条”社区，用户在上传照片后，系统会根据填写的参数，自动生成一条求助信息并发布在社区，社区内的用户会根据照片信息，在日常刷直播平台时对比相似度，发现小号后，会根据线索内的联系电话，联系照片上传人。双方就小号线索如何交割问题均自行协商，与本系统无关。</p>
        <br />
        <p> 本系统只收取上传并储存线索信息的技术服务费用，以“卡券”形式收取：</p>
        <br />
        <div>
          <p>上传新线索 3卡券（线索储存1一个月）</p>
          <p>追加平台 1卡券（每追加一个平台）</p>
          <p>1级加速 5卡券</p>
          <p>2级加速 10卡券</p>
          <p>3级加速 20卡券</p>
          <p>（加速即增加曝光量，提高找到小号的概率，升级仅支付差额，有效期随线索有效期同步）</p>
        </div>
        <br />
        <strong> 找到小号后的收费，由找到小号的人与用户自行协商</strong>
      </div>
    </el-dialog> -->
    <el-dialog :visible.sync="dialogVisible" width="500px">
      <div class="close pointer" @click="dialogVisible=false"></div>
    </el-dialog>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      dialogVisible: false,
    };
  },
  mounted () { },
  methods: {
    show () {
      this.dialogVisible = true;
    }
  },
};
 </script>
 <style lang='scss' scoped>
// .content {
//   line-height: 2;
//   height: 600px;
//   overflow-y: scroll;
//   /* 整个滚动条 */
//   &::-webkit-scrollbar {
//     width: 6px;
//     background-color: #ffffff;
//   }

//   /* 滚动条轨道 */
//   &::-webkit-scrollbar-track {
//     /* 阴影 */
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     background-color: #ffffff;
//   }

//   /* 滚定条滑块 */
//   &::-webkit-scrollbar-thumb {
//     border-radius: 30px;
//     background-color: #615b5b49;
//   }
// }
.beanRules {
  &/deep/.el-dialog {
    &__header {
      display: none;
    }
    &__body {
      background: url(~@/static/image/beanRules.png);
      height: 592px;
      position: relative;
    }
  }
  .close {
    background-image: url(~@/static/image/beanRules_close.png);
    width: 28px;
    height: 28px;
    position: absolute;
    bottom: -48px;
    left: 0px;
    right: 0px;
    margin: auto;
  }
}
</style>