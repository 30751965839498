/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-04 11:05:16
 * @Module: 通知弹窗
 */
 <template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="40%">
      <div class="content">
        <el-image style="width: 100%;" :src="require('@/static/image/inform.png')" fit="contain"></el-image>
      </div>
    </el-dialog>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      dialogVisible: false
    };
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
</style>