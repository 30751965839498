/* 
 * @Author: 曹俊杰 
 * @Date: 2022-05-20 17:43:54
 * @Module: 档案分享
 */
 <template>
  <div class="ArchiveShare">
    <div class="title" style="">
      <div @click="$router.push({path:'/archiveManagement'})">档案目录</div>
      <div class="active">分享列表</div>
    </div>
    <div style="padding:24px;padding-top:0px;">
      <div class="search">
        <el-input placeholder="请输入内容" style="width:220px;" prefix-icon="el-icon-search" v-model="search" size="mini" />
        <el-button type="primary" size="mini" style="margin-left:20px;">搜索</el-button>
      </div>
      <div class="item" v-for="(item,index) in filterRender(search,list)" :key="item.dirUuid">
        <div class="top">
          <div>
            <h1 class="pointer" @click="$router.push({path:'/archiveManagement/'+item.dirUuid})">档案：{{item.dirLabel}}
              <i class="el-icon-ali-fenxiang"></i>
            </h1>
            <h2>{{item.companyName}}</h2>
          </div>
          <div class="btn" v-if="item.companyUuid==user.companyUuid" @click="()=>deleteDirShare(item,index)">取消分享</div>
        </div>
        <el-table border :data="item.shares" tooltip-effect="dark" size="mini" style="width: 100%;margin-top:10px;">
          <el-table-column label="序号" show-overflow-tooltip width="45">
            <template>{{ index+1 }}</template>
          </el-table-column>
          <el-table-column label="分享方账号" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.sharerName }}
              <span style="color:#999;transform: scale(0.8);display:inline-block;transform-origin:left bottom;">({{scope.row.sharerCompanyName}})</span>

            </template>
          </el-table-column>
          <el-table-column label="接收方账号" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.receiverName }}
              <span style="color:#999;transform: scale(0.8);display:inline-block;transform-origin:left bottom;">({{scope.row.receiverCompanyName}})</span>
            </template>
          </el-table-column>
          <el-table-column label="分享日期" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createdAt | timeFormat}}</template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-empty :image-size="200" v-show="!filterRender(search,list).length"></el-empty>
  </div>
</template>
 <script>
import { mapState } from "vuex";
export default {
  data () {
    return {
      list: [],
      search: ''
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  mounted () {
    this.getshareList()
  },
  methods: {
    async getshareList () {
      const { status, data } = await this.$api.getshareGroupedList()
      if (status === 200) {
        this.list = data;
      }
    },
    filterRender (value, data) {
      let list = [];
      data.forEach(item => {
        if (item.dirLabel.indexOf(value) > -1 || (item.companyName.indexOf(value) > -1)) {
          list.push(item)
        } else {
          item.shares.forEach(itm => {
            if ((itm.sharerName.indexOf(value) > -1) || (itm.receiverName.indexOf(value) > -1)) {
              list.push(item)
            }
          })
        }
      })
      if (value) {
        return list
      } else {
        return data
      }
    },
    // 删除分享
    async ondelete (scope, idx) {
      const { row: { uuid } } = scope;
      const { status, info } = await this.$api.deleteShare({ shareUuid: uuid });
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        const index = this.list[idx].shares.findIndex(item => item.uuid == uuid);
        this.list[idx].shares.splice(index, 1);
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
    //  删除分享（分组）
    async deleteDirShare ({ dirUuid }) {
      this.$alert('此操作将取消分享该档案, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        callback: async action => {
          if (action == "confirm") {
            const { status, info } = await this.$api.deleteDirShare({ dirUuid });
            if (status == 200) {
              this.$message({
                type: 'success',
                message: info
              })
              const index = this.list.findIndex(item => item.dirUuid == dirUuid);
              this.list.splice(index, 1)
            } else {
              this.$message({
                type: 'error',
                message: info
              })
            }
          }
        }
      });
    }
  },
};
 </script>
 <style lang='scss' scoped>
@import "~@/assets/style/var.scss";
.ArchiveShare {
  background: #fff;
  border: #ffffff 1px solid;
  min-height: calc(100vh - 120px - 28px);
  .item {
    background: #f7f8f9;
    border-radius: 4px;
    border: 1px solid #eeeeee;
    padding: 14px 16px 10px 16px;
    margin-bottom: 20px;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #262626;
        line-height: 22px;
        i {
          color: $--color-primary;
        }
      }
      h2 {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
        margin-top: 4px;
      }
      .btn {
        width: 78px;
        height: 32px;
        border-radius: 2px;
        border: 1px solid #0062ff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0062ff;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.title {
  height: 50px;
  background: #ffffff;
  width: 100%;
  margin-top: 10px;
  border-bottom: 1px solid #e3e3e3;
  padding-left: 24px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 50px;
  display: flex;
  .active {
    color: #0062ff;
    border-bottom: 2px #0062ff solid;
    font-family: PingFangSC-Medium, PingFang SC;
  }
  div {
    cursor: pointer;
  }
  div:last-child {
    margin-left: 40px;
  }
}
.search {
  display: flex;
  height: 70px;
  align-items: center;
}
</style>