/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-18 15:04:44
 * @Module: 公告
 */
 <template>
  <div class="notice">
    <el-card shadow="never" :body-style="{minHeight: 'calc(100vh - 190px)',position:'relative',paddingTop:'24px'}">
      <div slot="header" class="header">
        <el-button type="text" class="back" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button>
        <div class="tittle">平台公告</div>
      </div>
      <hdq-cell v-for="item in list" :key="item.noticeId" :data="item" @click="()=>$router.push({path:`/notice/${item.noticeId}`})" />
      <hdq-pagination small ref="pagination" @change="paginationChange" />
    </el-card>
  </div>
</template>
 <script>
import hdqCell from "@/components/hdqCell.vue"
import hdqPagination from "@/components/hdqPagination.vue"
export default {
  components: {
    hdqCell,
    hdqPagination
  },
  data () {
    return {
      list: [
      ]
    };
  },
  mounted () {
    this.getNotice()
  },
  methods: {
    async getNotice (current = 1, size = 15) {
      const { status, data } = await this.$api.getNotice({ current, size })
      if (status === 200) {
        const { records } = data
        this.list = records
        this.$refs.pagination.createPagination(data)
      }
    },
    paginationChange ({ current, size }) {
      this.getNotice(current, size)
    }
  },
};
 </script>
 <style lang='scss' scoped>
.notice {
  .header {
    position: relative;
    .tittle {
      line-height: 50px;
      font-size: 16px;
      text-align: center;
      font-family: PingFangSC-Medium, PingFang SC;
      border-bottom: 1px solid #ebeef5;
    }
    .back {
      position: absolute;
      left: 24px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}
</style>