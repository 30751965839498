import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue' // 主页
import ElectronicDocuments from '@/views/ElectronicDocuments.vue'  // 电子文书
import AccurateEvidence from '@/views/AccurateEvidence.vue'  // 精准取证
import ArchiveManagement from '@/views/ArchiveManagement.vue'  // 归档管理
import ArchiveShare from '@/views/ArchiveShare.vue'  // 档案分享
import ArchiveManagementDetail from '@/views/ArchiveManagement/ArchiveManagementDetail.vue'  // 归档管理详情
import CaseProgress from '@/views/CaseProgress.vue'  // 案件进度
import AccountManagement from '@/views/AccountManagement.vue'  // 账号管理
import SealManagement from '@/views/SealManagement.vue'  // 印章管理
// import ExpenseCenter from '@/views/ExpenseCenter.vue'  // 费用中心
import ExpenseCenter from '@/views/VoucherCenter.vue'  // 费用中心

import InstituteJustice from '@/views/InstituteJustice.vue'  // 法务学院
import Help from '@/views/Help.vue'  // 帮助中心
import Notice from '@/views/Notice.vue'  // 公告
import Inspect from '@/views/Inspect.vue'  // 三要素核查
import Search from '@/views/Search.vue'  // 三要素核查
import Exposure from '@/views/Exposure.vue'  // 曝光平台
import Login from '@/views/Login.vue'  // 登录
import defaultLayouts from '@/layouts/default'
import store from '../store'
import { Message } from 'element-ui';
Vue.use(VueRouter)
// 重复跳转报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [
	{
		path: '/',
		component: defaultLayouts,
		redirect: '/home',
		children: [
			{
				path: '/home',
				name: 'home',
				meta: {
					menu: 'home',
					title: '工作台',
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: Home
			},
			{
				path: '/electronicDocuments',
				name: 'electronicDocuments',
				meta: {
					requireAuth: true,
					menu: 'electronicDocuments',
					title: '电子合同',
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: ElectronicDocuments
			},
			{
				path: '/search',
				name: 'search',
				meta: {
					requireAuth: true,
					menu: 'search',
					title: '查询小号',
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: Search
			},
			{
				path: '/search/searchForm',
				name: 'searchForm',
				meta: {
					requireAuth: true,
					menu: 'search',
					title: '查询小号',
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Search/SearchForm'),
			},
			{
				path: '/search/searchConformed',
				name: 'searchConformed',
				meta: {
					requireAuth: true,
					menu: 'search',
					title: '查询小号',
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Search/searchConformed'),
			},
			{
				path: '/accurateEvidence',
				name: 'accurateEvidence',
				meta: {
					menu: 'accurateEvidence',
					title: '精准取证',
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: AccurateEvidence,
			},
			{
				path: '/accurateEvidence/PCupload',
				name: 'PCupload',
				component: () => import('@/views/AccurateEvidence/PCupload'),
				meta: {
					menu: 'accurateEvidence',
					title: '精准取证',
				},
			},

			{
				path: '/SealManagement',
				name: 'sealManagement',
				meta: {
					menu: 'sealManagement',
					title: '印章管理',
				},
				component: SealManagement,
			},
			{
				path: '/SealManagement/:sealId',
				name: 'sealManagementDetail',
				meta: {
					menu: 'sealManagement',
					title: '印章管理',
				},
				component: () => import('@/views/SealManagement/SealManagementDetail')
			},
			{
				path: '/archiveShare',
				name: 'archiveShare',
				meta: {
					menu: 'archiveManagement',
					title: '归档管理',
				},
				component: ArchiveShare,
			},
			{
				path: '/archiveManagement',
				name: 'archiveManagement',
				meta: {
					menu: 'archiveManagement',
					title: '归档管理',
				},
				redirect: '/archiveManagement/0',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: ArchiveManagement,
				children: [
					{
						path: '/archiveManagement/:id',
						component: ArchiveManagementDetail,
						meta: {
							menu: 'archiveManagement',
							title: '归档管理',
						},
					}
				]

			},
			{
				path: '/caseProgress',
				name: 'caseProgress',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: CaseProgress,
				meta: {
					menu: 'caseProgress'
				},
			},
			{
				path: '/exposure',
				name: 'exposure',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: Exposure,
				meta: {
					menu: 'exposure',
					title: '曝光列表'
				},
				redirect: '/exposure/all',
				children: [
					{
						path: '/exposure/all',
						component: () => import('@/views/Exposure/All'),
						meta: {
							menu: 'exposure',
							hiddenFooter: true,
							title: '曝光列表'
						},
						name: 'all'
					},
					{
						path: '/exposure/mine',
						component: () => import('@/views/Exposure/Mine'),
						meta: {
							menu: 'exposure',
							hiddenFooter: true,
							title: '曝光列表'
						},
						name: 'mine'
					}
				]
			},
			{
				path: '/inspect',
				name: 'inspect',
				// redirect: '/inspect/inspectSearch',
				meta: {
					menu: 'inspect',
					title: '三要素核查'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: Inspect,
			},
			{
				path: '/inspect/inspectSearch',
				name: 'inspiectSearch',
				meta: {
					menu: 'inspect',
					title: '三要素核查'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Inspect/InspectSearch')
			},
			{
				path: '/inspect/inspectResult',
				name: 'inspectResult',
				meta: {
					menu: 'inspect',
					title: '三要素核查'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Inspect/InspectResult')
			},
			{
				path: '/accountManagement',
				name: 'accountManagement',
				meta: {
					menu: 'accountManagement',
					title: '个人中心'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: AccountManagement,
				redirect: '/accountManagement/personal',
				children: [
					{
						path: '/accountManagement/personal',
						component: () => import('@/views/AccountManagement/Personal'),
						meta: {
							menu: 'accountManagement',
							title: '个人中心'
						},
						name: 'personal'
					},
					{
						path: '/accountManagement/sealManagement',
						component: () => import('@/views/AccountManagement/SealManagement'),
						meta: {
							menu: 'accountManagement',
							title: '个人中心'
						},
						name: 'sealManagement'
					}
				]
			},
			{
				path: '/accountManagement/accountAuth',
				name: 'accountAuth',
				meta: {
					menu: 'accountManagement',
					title: '个人中心'
				},
				component: () => import('@/views/AccountManagement/AccountAuth'),


			},
			{
				path: '/accountManagement/accountCompanyAuth',
				name: 'accountCompanyAuth',
				meta: {
					menu: 'accountManagement',
					title: '个人中心'
				},
				component: () => import('@/views/AccountManagement/AccountCompanyAuth')
			},
			{
				path: '/expenseCenter',
				name: 'expenseCenter',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: ExpenseCenter,
				redirect: '/expenseCenter/recharge',
				meta: {
					menu: 'expenseCenter',
					title: '费用中心'
				},
				children: [{
					path: '/expenseCenter/topUp',
					name: 'topUp',
					meta: {
						menu: 'expenseCenter',
						title: '费用中心'
					},
					component: () => import('@/views/ExpenseCenter/TopUp')
				},
				{
					path: '/expenseCenter/packages',
					name: 'packages',
					meta: {
						menu: 'expenseCenter',
						title: '费用中心'
					},
					component: () => import('@/views/ExpenseCenter/Packages')
				},
				{
					path: '/expenseCenter/recharge',
					name: 'recharge',
					meta: {
						menu: 'expenseCenter',
						title: '费用中心'
					},
					component: () => import('@/views/ExpenseCenter/Recharge')
				},
				{
					path: '/expenseCenter/expense',
					name: 'expense',
					meta: {
						menu: 'expenseCenter',
						title: '费用中心'
					},
					component: () => import('@/views/ExpenseCenter/Expense')
				},
				{
					path: '/expenseCenter/address',
					name: 'address',
					meta: {
						menu: 'expenseCenter',
						title: '费用中心'
					},
					component: () => import('@/views/ExpenseCenter/Address')
				}
				]
			},
			{
				path: '/expenseCenter/cashier',
				name: 'cashier',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/VoucherCenter/Cashier'),
				meta: {
					menu: 'expenseCenter',
					title: '费用中心'
				},
				children: [
					{
						path: '/expenseCenter/cashier/gold',
						name: 'gold',
						// route level code-splitting
						// this generates a separate chunk (about.[hash].js) for this route
						// which is lazy-loaded when the route is visited.
						component: () => import('@/views/VoucherCenter/Cashier/Gold'),
						meta: {
							menu: 'expenseCenter',
							title: '费用中心'
						},
					},
					{
						path: '/expenseCenter/cashier/coupon',
						name: 'coupon',
						// route level code-splitting
						// this generates a separate chunk (about.[hash].js) for this route
						// which is lazy-loaded when the route is visited.
						component: () => import('@/views/VoucherCenter/Cashier/Coupon'),
						meta: {
							menu: 'expenseCenter',
							title: '费用中心'
						},
					}
				]
			},
			{
				path: '/instituteJustice',
				name: 'instituteJustice',
				meta: {
					menu: 'instituteJustice',
					keepAlive: true,
					ifDoFresh: false,
					title: '法务学院'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: InstituteJustice
			},
			{
				path: '/instituteJustice/:id',
				name: 'instituteJusticeDetail',
				meta: {
					menu: 'instituteJustice',
					title: '法务学院'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/InstituteJustice/InstituteJusticeDetail')
			},
			{
				path: '/help',
				name: 'help',
				meta: {
					menu: 'help',
					title: '帮助中心'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: Help,
				children: [
					// {
					// 	path: '',
					// 	component: () => import('@/views/Help/HelpDefault'),
					// 	meta: {
					// 		menu: 'help'
					// 	},
					// },
					{
						path: '/help/:id',
						component: () => import('@/views/Help/HelpDetail'),
						meta: {
							menu: 'help',
							title: '帮助中心'
						},
					}
				]
			},
			{
				path: '/notice',
				name: 'notice',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: Notice,
				meta: {
					menu: 'notice',
					title: '平台公告'
				}
			},
			{
				path: '/notice/:id',
				component: () => import('@/views/Notice/NoticeDetail'),
				meta: {
					menu: 'notice',
					title: '平台公告'
				},
			},
			{
				path: '/accountColleague',
				name: 'accountColleague',
				meta: {
					menu: 'accountColleague',
					title: '员工管理',
				},
				component: () => import('@/views/AccountColleague'),
			},
		]
	},
	{
		path: '/auth',
		component: () => import('@/layouts/auth'),
		redirect: '/auth/CompanyAuth',

		children: [{
			path: '/auth/UserAuth',
			name: 'UserAuth',
			component: () => import('@/views/UserAuth')
		},
		{
			path: '/auth/CompanyAuth',
			name: 'CompanyAuth',
			component: () => import('@/views/CompanyAuth')
		}]
	},
	{
		component: () => import('@/layouts/electronicDocuments'),
		path: '',
		children: [
			{
				path: '/electronicDocuments/electronicDocumentsPDF',
				name: 'electronicDocumentsPDF',
				meta: {
					menu: 'electronicDocuments'
				},
				component: () => import('@/views/ElectronicDocuments/ElectronicDocumentsPDF')
			},
			{
				path: '/electronicDocuments/electronicDocumentsAdd',
				name: 'electronicDocumentsAdd',
				meta: {
					menu: 'electronicDocuments'
				},
				component: () => import('@/views/ElectronicDocuments/ElectronicDocumentsAdd')
			},
			{
				path: '/electronicDocuments/electronicDocumentsSuccess',
				name: 'electronicDocumentsSuccess',
				meta: {
					menu: 'electronicDocuments'
				},
				component: () => import('@/views/ElectronicDocuments/ElectronicDocumentsSuccess')
			},
			{
				path: '/companyProfile',
				name: 'companyProfile',
				component: () => import('@/views/CompanyProfile')
			},
			{
				path: '/exposure/form',
				name: 'exposureForm',
				component: () => import('@/views/Exposure/Form')
			}
		]
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '*',
		name: 'error_404',
		component: () => import('@/views/404'),
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
router.beforeEach((to, from, next) => {
	// const { token } = store.state.user.user
	// if (to.matched.some(record => record.meta.requireAuth)) {  // 判断该路由是否需要登录权限
	// 	if (token) {  // 判断当前的token是否存在
	// 		next();
	// 	}
	// 	else {
	// 		next({
	// 			path: '/login',
	// 			query: { redirect: to.fullPath }  // 将跳转的路由path作为参数，登录成功后跳转到该路由
	// 		})
	// 	}
	// }
	// else {
	// 	next();
	// }
	if (['expenseCenter', 'electronicDocuments', 'exposure', 'archiveManagement', 'inspectSearch', 'inspect', 'sealManagement', 'accountColleague'].includes(to.meta.menu)) {
		const { company, creator } = store.state.user.user
		if (company) {
			if (['accountColleague', 'sealManagement'].includes(to.meta.menu)) {
				if (creator) {
					next()
				} else {
					Message({
						message: '此功能需要法人账号操作',
						type: 'warning'
					})
				}
			} else {
				next()
			}
		} else {
			next({
				path: '/auth/CompanyAuth'
			})
			Message({
				message: '此功能需加入/创建企业',
				type: 'warning'
			})
		}
	} else {
		next()
	}
})
export default router
