
const state = {
	electronicData: {
		subject: '',
		files: [],
		signers: [],
		file: '',
		save: false
	},
	enterpriseSearch: [],   // 企业查询
	personalSearch: []  // 个人查询

}
const mutations = {
	setElectronic (state, payload) {
		state.electronicData = {
			...state.electronicData,
			...payload
		}
	},

	// 保存历史记录
	saveHistory (state, payload) {
		payload.forEach(item => {
			if (item.type === 'ORG') {
				if (state.enterpriseSearch.findIndex(itm => (itm.operatorIdNumber == item.operatorIdNumber) && (itm.idNumber == item.idNumber)) < 0)
					state.enterpriseSearch.push({
						...item
					})
			} else if (item.type === 'PERSON') {
				if (state.personalSearch.findIndex(itm => itm.operatorIdNumber == item.operatorIdNumber) < 0)
					state.personalSearch.push({
						...item
					})
			}
		});

	}
}

export default {
	namespaced: true,
	state,
	mutations
}