/* 
 * @Author: 曹俊杰 
 * @Date: 2023-01-03 16:02:31
 * @Module: 菜单
 */
 <template>
  <div class="menu" :class="{collapse:isCollapse}">
    <div class="top">
      <div class="logo"></div>
      <i :class="isCollapse?'el-icon-ali-youbian':'el-icon-ali-zuobian'" class="pointer" @click="isCollapse=!isCollapse"></i>
    </div>

    <div class="content">
      <template v-for="item in menuList">
        <div :key="item.name" v-if="item.divided" class="divided">
          <span>{{item.divided}}</span>
          <el-tooltip :disabled="!isCollapse" :content="item.divided" placement="right" effect="light">
            <i></i>
          </el-tooltip>
        </div>

        <div v-else-if="!item.divided" :key="item.name" class="menuItem" :class="item.name=== defaultActive?'active not-allowed':'pointer'" @click="$router.push({name: item.name})">
          <el-tooltip :disabled="!isCollapse" :content="item.title" placement="right" effect="light">
            <i :class="item.name==defaultActive?item.activeIcon:item.icon"></i>
          </el-tooltip>

          <span class="text">{{item.title}}</span>
        </div>
      </template>
    </div>
  </div>
</template>
 <script>
import { mapState } from "vuex";
export default {
  components: {},
  data () {
    return {
      menuList: [
        {
          title: '工作台',
          name: 'home',
          icon: 'el-icon-ali-gongzuotai1',
          activeIcon: 'el-icon-ali-gongzuotai',
          authority: 'all'
        },
        {
          divided: '文件管理',
          name: 'wjgl'
        },
        {
          title: '电子合同',
          name: 'electronicDocuments',
          icon: 'el-icon-ali-dianzihetong12',
          activeIcon: 'el-icon-ali-dianzihetong2',
          authority: 'all'
        },
        {
          title: '精准取证',
          name: 'accurateEvidence',
          icon: 'el-icon-ali-jingzhunquzheng11',
          activeIcon: 'el-icon-ali-jingzhunquzheng2',
          authority: 'all'
        },
        {
          title: '归档管理',
          name: 'archiveManagement',
          icon: 'el-icon-ali-guidangguanli11',
          activeIcon: 'el-icon-ali-guidangguanli1',
          authority: 'all'
        },
        {
          divided: '专属功能',
          name: 'zsgn'
        },
        {
          title: '三要素核查',
          name: 'inspect',
          icon: 'el-icon-ali-sanyaosuhecha11',
          activeIcon: 'el-icon-ali-sanyaosuhecha2',
          authority: 'all'
        },
        // {
        //   title: '查询小号',
        //   name: 'search',
        //   icon: 'el-icon-ali-chaxunxiaohao1',
        //   activeIcon: 'el-icon-ali-chaxunxiaohao',
        //   authority: 'all'
        // },
        {
          divided: '账号管理',
          name: 'zhgl'
        },
        {
          title: '个人中心',
          name: 'accountManagement',
          icon: 'el-icon-ali-gerenzhongxin1',
          activeIcon: 'el-icon-ali-gerenzhongxin',
          authority: 'all'
        },
        {
          title: '费用中心',
          name: 'expenseCenter',
          icon: 'el-icon-ali-feiyongzhongxin12',
          activeIcon: 'el-icon-ali-feiyongzhongxin2',
          authority: 'all'
        },
        {
          title: '员工管理',
          name: 'accountColleague',
          icon: 'el-icon-ali-yuangongguanli11',
          activeIcon: 'el-icon-ali-yuangongguanli2',
          authority: 'all'
        },
        {
          title: '印章管理',
          name: 'sealManagement',
          icon: 'el-icon-ali-yinzhangguanli1',
          activeIcon: 'el-icon-ali-yinzhangguanli',
          authority: 'all'
        },
        {
          title: '法务学院',
          name: 'instituteJustice',
          icon: 'el-icon-ali-fawuxueyuan',
          activeIcon: 'el-icon-ali-fawuxueyuan',
          authority: 'all'
        },
        {
          title: '帮助中心',
          name: 'help',
          icon: 'el-icon-ali-wenhao-bangzhu',
          activeIcon: 'el-icon-ali-wenhao-bangzhu',
          authority: 'all'
        }
      ],
      defaultActive: 'home',
      isCollapse: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  watch: {
    "$route.meta": {
      handler ({ menu }) {
        this.defaultActive = menu
      },
      immediate: true
    }
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.menu {
  background: #131d35;
  height: 100vh;
  color: #fff;
  width: 200px;

  .top {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .logo {
      background-image: url(~@/static/logo2.png);
      width: 100px;
      height: 38.2px;
      background-size: 100px 38.2px;
      background-repeat: no-repeat;
      margin-right: 36px;
    }
  }

  .content {
    font-size: 15px;
    line-height: 22px;

    height: calc(100vh - 100px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    .divided,
    .menuItem {
      height: 46px;
      padding: 0 30px;
      display: flex;
      align-items: center;
    }
    .divided {
      color: #424a5d;
      margin-top: 10px;
    }
    .menuItem {
      color: #8796a8;
      .text {
        margin-left: 10px;
      }
      &:hover {
        @extend .active;
      }
    }
    .active {
      background: #0f2d52;
      color: #fff;
    }
    .not-allowed {
      cursor: not-allowed;
    }
  }
}
.collapse {
  width: 70px;
  .top {
    .logo {
      display: none;
    }
  }
  .content {
    .divided,
    .menuItem {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .divided {
      span {
        display: none;
      }
      i::before {
        font-family: "iconfont";
        content: "\e60c";
      }
    }
    .menuItem {
      .text {
        display: none;
      }
    }
  }
}
</style>